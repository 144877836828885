import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import store from '../store'

export const constantRoutes = [
  {
    name: 'about',
    path: '/about',
    component: () => import('@/views/about/index.vue')
  },
  {
    name: 'careers',
    path: '/careers',
    component: () => import('@/views/careers/index.vue')
  },
  {
    name: 'position',
    path: '/careers/:url',
    component: () => import('@/views/careers/position/index.vue')
  },
  {
    name: 'apidays-live-hongkong',
    path: '/events/apidays-live-hongkong',
    component: () => import('@/views/events/apidays-live-hongkong/index.vue')
  },
  {
    name: 'apidays-2020-recap',
    path: '/events/apidays-live-hongkong/recap',
    component: () => import('@/views/events/apidays-live-hongkong/recap/index.vue')
  },
  {
    name: 'apidays-live-hongkong-tc',
    path: '/events/apidays-live-hongkong-tc',
    component: () => import('@/views/events/apidays-live-hongkong/index-tc.vue')
  },
  {
    name: 'apidays-2020-recap-tc',
    path: '/events/apidays-live-hongkong-tc/recap',
    component: () => import('@/views/events/apidays-live-hongkong/recap/index-tc.vue')
  },
  {
    name: 'apidays-live-hongkong-2021',
    path: '/events/apidays-live-hongkong-2021',
    component: () => import('@/views/events/apidays-live-hongkong-2021/index.vue')
  },
  {
    name: 'apidays-2021-recap',
    path: '/events/apidays-live-hongkong-2021/recap',
    component: () => import('@/views/events/apidays-live-hongkong-2021/recap/index.vue')
  },
  {
    name: 'apidays-live-hongkong-2022',
    path: '/events/apidays-live-hongkong-2022',
    component: () => import('@/views/events/apidays-live-hongkong-2022/index.vue')
  },
  {
    name: 'apidays-2022-recap',
    path: '/events/apidays-live-hongkong-2022/recap',
    component: () => import('@/views/events/apidays-live-hongkong-2022/recap/index.vue')
  },
  {
    name: 'apidays-hongkong-2023',
    path: '/events/apidays-hongkong-2023',
    component: () => import('@/views/events/apidays-hongkong-2023/index.vue')
  },
  {
    name: 'apiecobooster',
    path: '/events/apiecobooster',
    component: () => import('@/views/events/apiecobooster/index.vue')
  },
  {
    name: 'events',
    path: '/events',
    component: () => import('@/views/events/index.vue')
  },
  {
    name: 'event',
    path: '/events/:event',
    component: () => import('@/views/events/detail/index.vue')
  },
  {
    name: 'index',
    path: '/',
    component: () => import('@/views/index/index.vue')
  },
  {
    name: 'partners',
    path: '/partners',
    component: () => import('@/views/partner/index.vue')
  },
  {
    name: 'privacy',
    path: '/privacy-policy',
    component: () => import('@/views/privacy-policy/index.vue')
  },
  {
    name: 'resource-library',
    path: '/resource-library',
    component: () => import('@/views/resource-library/index.vue')
  },
  {
    name: 'benovelty-onedegree-cyber-insurance',
    path: '/resource-library/benovelty-onedegree-cyber-insurance',
    component: () => import('@/views/resource-library/benovelty-onedegree-cyber-insurance/index.vue')
  },
  {
    name: 'benovelty-onedegree-cyber-insurance-tc',
    path: '/resource-library/benovelty-onedegree-cyber-insurance-tc',
    component: () => import('@/views/resource-library/benovelty-onedegree-cyber-insurance/index-tc.vue')
  },
  {
    name: 'banking-open-api',
    path: '/resource-library/banking-open-api',
    component: () => import('@/views/resource-library/banking-open-api/index.vue')
  },
  /*
   * Start - Reserved route waiting to be deleted | Webinar events are now migrated to Events page
  **/
  // {
  //   name: 'aws_techconnect_series',
  //   path: '/resource-library/aws_techconnect_series',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: '20201112_benovelty_onedegree_fiberapi',
  //   path: '/resource-library/20201112_benovelty_onedegree_fiberapi',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'fintech_and_the_new_normal',
  //   path: '/resource-library/fintech_and_the_new_normal',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'driving_innovations_in_open_banking',
  //   path: '/resource-library/driving_innovations_in_open_banking',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'executive_seminar_technologies_in_finance',
  //   path: '/resource-library/executive_seminar_technologies_in_finance',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'future_of_law_legal_innovations_in_action_today',
  //   path: '/resource-library/future_of_law_legal_innovations_in_action_today',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'local_start-up_experience_sharing',
  //   path: '/resource-library/local_start-up_experience_sharing',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: '20200623_benovelty_taptab_boutir',
  //   path: '/resource-library/20200623_benovelty_taptab_boutir',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'opportunity-for-education-industry-digital-transformation',
  //   path: '/resource-library/opportunity-for-education-industry-digital-transformation',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'api_trends_and_challenges',
  //   path: '/resource-library/api_trends_and_challenges',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  // {
  //   name: 'open_API_technology_entrepreneurial_journey_sharing',
  //   path: '/resource-library/open_API_technology_entrepreneurial_journey_sharing',
  //   component: () => import('@/views/resource-library/detail/index.vue')
  // },
  /*
   * End - Reserved route waiting to be deleted | Webinar events are now migrated to Events page
  **/
  {
    name: 'resource',
    path: '/resource-library/:resource',
    component: () => import('@/views/resource-library/webinar/index.vue')
  },
  {
    name: 'terms-of-use',
    path: '/terms-of-use',
    component: () => import('@/views/terms-of-use/index.vue')
  },
  {
    name: 'trust',
    path: '/trust',
    component: () => import('@/views/trust/index.vue')
  },
  {
    name: 'error404',
    path: '*',
    component: () => import('@/views/error404/index.vue')
  },
  {
    name: 'press',
    path: '/press',
    component: () => import('@/views/press/index.vue')
  },
  {
    name: 'hsbc',
    path: '/case-study/hsbc',
    component: () => import('@/views/case-study/hsbc/index.vue')
  },
  {
    name: 'DeveloperPortal',
    path: '/products/api-developer-portal',
    component: () => import('@/views/products/api-developer-portal/index.vue')
  },
  {
    name: 'FiberConnect',
    path: '/products/fiberconnect',
    component: () => import('@/views/products/fiberconnect/index.vue')
  },
  {
    name: 'FabriXAPI',
    path: '/products/fabrixapi',
    component: () => import('@/views/products/fabrixapi/index.vue')
  },
  {
    name: 'OpenAPIHub',
    path: '/products/openapihub',
    component: () => import('@/views/products/openapihub/index.vue')
  },
  {
    name: 'FabriXAI',
    path: '/products/fabrixai',
    component: () => import('@/views/products/fabrixai/index.vue')
  },
  {
    name: 'TapTab',
    path: '/products/taptab',
    component: () => import('@/views/products/taptab/index.vue')
  },
  {
    name: 'HKOpenBankingSDK',
    path: '/products/hk-open-banking-sdk',
    component: () => import('@/views/products/hk-open-banking-sdk/index.vue')
  },
  {
    name: 'ApiProduct',
    path: '/solutions/api-product',
    component: () => import('@/views/solutions/api-product/index.vue')
  },
  {
    name: 'ApiConsultancy',
    path: '/solutions/api-consultancy',
    component: () => import('@/views/solutions/api-consultancy/index.vue')
  },
  {
    name: 'ApiManagement',
    path: '/solutions/api-management',
    component: () => import('@/views/solutions/api-management/index.vue')
  },
  {
    name: 'KongEnterpriseApiSolution',
    path: '/solutions/api-management/kong-enterprise-api-solution',
    component: () => import('@/views/solutions/kong-enterprise-api-solution/index.vue')
  },
  {
    name: 'ApiEducation',
    path: '/solutions/api-education',
    component: () => import('@/views/solutions/api-education/index.vue')
  },
  {
    name: 'LegoSeriousPlay',
    path: '/solutions/api-education/lego-serious-play',
    component: () => import('@/views/solutions/api-education/lego-serious-play/index.vue')
  },
  {
    name: 'ApiCommunity',
    path: '/solutions/api-community',
    component: () => import('@/views/solutions/api-community/index.vue')
  },
  {
    name: 'AiGateway',
    path: '/solutions/ai-gateway',
    component: () => import('@/views/solutions/ai-gateway/index.vue')
  },
  {
    name: 'ApiFirstArchitecture',
    path: '/solutions/api-first-architecture',
    component: () => import('@/views/solutions/api-first-architecture/index.vue')
  },
  {
    name: 'AiAgent',
    path: '/solutions/ai-agent',
    component: () => import('@/views/solutions/ai-agent/index.vue')
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/contact/index.vue')
  },
  {
    name: 'oah-terms-of-use',
    path: '/openapihub/terms-of-use',
    component: () => import('@/views/oah-terms-of-use/index.vue')
  },
  {
    name: 'oah-privacy',
    path: '/openapihub/privacy-policy',
    component: () => import('@/views/oah-privacy-policy/index.vue')
  },
  {
    name: 'fabrixapi-terms-of-use',
    path: '/fabrixapi/terms-of-use',
    component: () => import('@/views/fabrixapi-terms-of-use/index.vue')
  },
  {
    name: 'fabrixapi-privacy',
    path: '/fabrixapi/privacy-policy',
    component: () => import('@/views/fabrixapi-privacy-policy/index.vue')
  }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from, next) => {
  console.log('setShowDummy', true)
  store.dispatch('app/setShowDummy', true)
  next()
})
router.afterEach((to, from) => {
  console.log('setShowDummy', false)
  store.dispatch('app/setShowDummy', false)
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
