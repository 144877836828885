<template>
  <header
    id="header"
    class="header header-box-shadow-on-scroll header-bg-transparent header-abs-top header-show-hide"
    data-hs-header-options="{
      &quot;fixMoment&quot;: 1000,
      &quot;fixEffect&quot;: &quot;slide&quot;
    }"
  >
    <div class="header-section">
      <div id="logoAndNav" class="container">
        <!-- Nav -->
        <nav class="js-mega-menu navbar navbar-expand-lg">
          <!-- Logo -->
          <router-link class="navbar-brand" :to="{ path: '/' }" aria-label="beNovelty">
            <img src="@/assets/svg/logos/logo.svg" alt="beNovelty">
          </router-link>
          <!-- End Logo -->

          <!-- Responsive Toggle Button -->
          <!-- <button
            id="navHideButton"
            :aria-expanded="menu_expanded"
            type="button"
            class="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
            aria-label="Toggle navigation"
            aria-controls="navBar"
            data-toggle="collapse"
            data-target="#navBar"
            @click="expandMenu"
          > -->
          <button
            id="navHideButton"
            :aria-expanded="menu_expanded"
            type="button"
            class="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
            aria-label="Toggle navigation"
            aria-controls="navBar"
            @click="expandMenu"
          >
            <span class="navbar-toggler-default">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </span>
          </button>
          <!-- End Responsive Toggle Button -->

          <!-- Navigation -->
          <div id="navBar" class="navbar-nav-wrap-navbar collapse navbar-collapse">
            <div class="navbar-body header-abs-top-inner">
              <ul class="navbar-nav">
                <!-- Solutions -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="solutionsMegaMenu" class="hs-mega-menu-invoker nav-link" href="javascript:;" aria-haspopup="true" aria-labelledby="solutionsSubMenu">Solutions <i class="fas fa-angle-down ml-2" /></a>
                  <div id="solutionsSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="solutionsMegaMenu" style="min-width: 230px;">
                    <span class="d-block h5 mb-0" style="padding: 1rem 1.5rem 0.5rem 1.5rem;color: #505050;">API-First Enterprise Solution</span>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/api-first-architecture' }" @click.native="hideMenu('solutionsMegaMenu')">API Gateway and Infrastructure Development</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/api-product' }" @click.native="hideMenu('solutionsMegaMenu')">API and Micro-Services Development</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/api-community' }" @click.native="hideMenu('solutionsMegaMenu')">API Strategy and Ecosystem Development</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/api-education/lego-serious-play' }" @click.native="hideMenu('solutionsMegaMenu')">API-First Training & Hackathon</router-link>

                    <div class="dropdown-divider" />

                    <span class="d-block h5 mb-0" style="padding: 1rem 1.5rem 0.5rem 1.5rem;color: #505050;">Agentic AI Solution</span>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">AI Gateway and Infrastructure</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">AI Agents Development</router-link>
                    <router-link class="dropdown-item" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">RAG, LLM Fine Tuning and Guardrails Implementation</router-link>
                    <!-- <a class="dropdown-item" href="/solutions/api-consultancy" @click="hideMenu('solutionsMegaMenu')">API Consultancy</a> -->
                    <!-- <router-link class="dropdown-item" :to="{ path: '/solutions/api-consultancy' }" @click.native="hideMenu('solutionsMegaMenu')">API Consultancy</router-link> -->
                    <!-- <router-link class="dropdown-item" :to="{ path: '/solutions/ai-gateway' }" @click.native="hideMenu('solutionsMegaMenu')">AI Gateway</router-link> -->
                    <!-- <a class="dropdown-item" href="/solutions/api-product" @click="hideMenu('solutionsMegaMenu')">API Product</a> -->
                    <!-- <router-link class="dropdown-item" :to="{ path: '/solutions/api-product' }" @click.native="hideMenu('solutionsMegaMenu')">API Product</router-link> -->
                    <!-- <div class="hs-has-sub-menu">
                      <router-link id="navLinkAPIManagement" class="hs-mega-menu-invoker dropdown-item dropdown-item-toggle" :to="{ path: '/solutions/api-management' }" aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuAPIManagement" @click.native="hideMenu('solutionsMegaMenu')">API Management</router-link>
                      <div id="navSubmenuAPIManagement" class="hs-sub-menu dropdown-menu" aria-labelledby="navLinkAPIManagement" style="min-width: 230px;">
                        <router-link class="dropdown-item" :to="{ path: '/solutions/api-management/kong-enterprise-api-solution' }" @click.native="hideMenu('solutionsMegaMenu')">Enterprise API Solution (Kong Konnect)</router-link>
                      </div>
                    </div> -->
                    <!-- <router-link class="dropdown-item" :to="{ path: '/solutions/api-community' }" @click.native="hideMenu('solutionsMegaMenu')">API Community</router-link> -->

                    <!-- <div class="hs-has-sub-menu">
                      <router-link id="navLinkAPIEducation" class="hs-mega-menu-invoker dropdown-item dropdown-item-toggle" :to="{ path: '/solutions/api-education' }" aria-haspopup="true" aria-expanded="false" aria-controls="navSubmenuAPIEducation" @click.native="hideMenu('solutionsMegaMenu')">API Education</router-link>
                      <div id="navSubmenuAPIEducation" class="hs-sub-menu dropdown-menu" aria-labelledby="navLinkAPIEducation" style="min-width: 230px;">
                        <router-link class="dropdown-item" :to="{ path: '/solutions/api-education/lego-serious-play' }" @click.native="hideMenu('solutionsMegaMenu')">LEGO® SERIOUS PLAY® Workshop</router-link>
                      </div>
                    </div> -->
                  </div>
                </li>
                <!-- End Solutions -->

                <!-- Products -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="productsMegaMenu" class="hs-mega-menu-invoker nav-link" href="javascript:;" aria-haspopup="true" aria-labelledby="productsSubMenu">Products <i class="fas fa-angle-down ml-2" /></a>
                  <div id="productsSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="productsMegaMenu" style="min-width: 400px;">
                    <!-- API-First Products -->
                    <span class="d-block h5 mb-0" style="padding: 1rem 1.5rem 0rem 1.5rem;color: #505050;">API Ecosystems &amp; Community</span>
                    <router-link id="ga-BW-CC03-02-02" class="dropdown-item navbar-promo-link" :to="{ path: '/products/fabrixapi' }" @click.native="hideMenu('productsMegaMenu')">
                      <div class="media align-items-center">
                        <img class="navbar-promo-icon" src="@/assets/svg/icons/icon-44.svg">
                        <div class="media-body">
                          <span class="navbar-promo-title">
                            FabriXAPI
                          </span>
                          <small class="navbar-promo-text">API Management Platform</small>
                        </div>
                      </div>
                    </router-link>
                    <router-link id="ga-BW-CC03-02-01" class="dropdown-item navbar-promo-link" :to="{ path: '/products/openapihub' }" @click.native="hideMenu('productsMegaMenu')">
                      <div class="media align-items-center">
                        <img class="navbar-promo-icon" src="@/assets/svg/icons/icon-38.svg">
                        <div class="media-body">
                          <span class="navbar-promo-title">
                            OpenAPIHub
                          </span>
                          <small class="navbar-promo-text">API Directory and Community</small>
                        </div>
                      </div>
                    </router-link>
                    <router-link class="dropdown-item navbar-promo-link" :to="{ path: '/products/fiberconnect' }" @click.native="hideMenu('productsMegaMenu')">
                      <div class="media align-items-center">
                        <img class="navbar-promo-icon" src="@/assets/svg/icons/icon-22.svg">
                        <div class="media-body">
                          <span class="navbar-promo-title">
                            FiberConnect
                          </span>
                          <small class="navbar-promo-text">API Connectivity for Financial Services</small>
                        </div>
                      </div>
                    </router-link>
                    <!-- AI Agent Products -->
                    <span class="d-block h5 mb-0" style="padding: 1rem 1.5rem 0rem 1.5rem;color: #505050;">AI Agent Products</span>
                    <router-link class="dropdown-item navbar-promo-link" :to="{ path: '/products/fabrixai' }" @click.native="hideMenu('productsMegaMenu')">
                      <div class="media align-items-center">
                        <img class="navbar-promo-icon" src="@/assets/svg/icons/icon-69.svg">
                        <div class="media-body">
                          <span class="navbar-promo-title">
                            FabriXAI
                          </span>
                          <small class="navbar-promo-text">AI Agent Platform</small>
                        </div>
                      </div>
                    </router-link>
                    <router-link class="dropdown-item navbar-promo-link" :to="{ path: '/products/taptab' }" @click.native="hideMenu('productsMegaMenu')">
                      <div class="media align-items-center">
                        <img class="navbar-promo-icon" src="@/assets/svg/icons/icon-4.svg">
                        <div class="media-body">
                          <span class="navbar-promo-title">
                            TapTab
                          </span>
                          <small class="navbar-promo-text">AI Agents for Education</small>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </li>
                <!-- End Products -->

                <!-- Resources -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="resourcesMegaMenu" class="hs-mega-menu-invoker nav-link" href="javascript:;" aria-haspopup="true" aria-labelledby="resourcesSubMenu">Resources <i class="fas fa-angle-down ml-2" /></a>
                  <div id="resourcesSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="resourcesMegaMenu" style="min-width: 230px;">
                    <!-- <a class="dropdown-item" href="/events" @click="hideMenu('resourcesMegaMenu')">Events</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/events' }" @click.native="hideMenu('resourcesMegaMenu')">Events</router-link>
                    <!-- <a class="dropdown-item" href="/resource-library" @click="hideMenu('resourcesMegaMenu')">Resource Library</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/resource-library' }" @click.native="hideMenu('resourcesMegaMenu')">Resource Library</router-link>
                    <div class="dropdown-divider" />
                    <a class="dropdown-item" href="https://www.apidays.hk/2023" target="_blank" @click.native="hideMenu('resourcesMegaMenu')">Apidays Hong Kong 2023<i class="fas fa-external-link-alt pl-2 font-size-1" /></a>

                  </div>
                </li>
                <!-- End Resources -->

                <!-- Company -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="companyMegaMenu" class="hs-mega-menu-invoker nav-link" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="companySubMenu">Company <i class="fas fa-angle-down ml-2" /></a>
                  <div id="companySubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="companyMegaMenu" style="min-width: 230px;">
                    <!-- <a class="dropdown-item" href="/about" @click="hideMenu('companyMegaMenu')">About</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/about' }" @click.native="hideMenu('companyMegaMenu')">About</router-link>
                    <!-- <a class="dropdown-item" href="/trust" @click="hideMenu('companyMegaMenu')">Trust</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/trust' }" @click.native="hideMenu('companyMegaMenu')">Trust</router-link>
                    <!-- <a class="dropdown-item" href="/careers" @click="hideMenu('companyMegaMenu')">Careers</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/careers' }" @click.native="hideMenu('companyMegaMenu')">Careers</router-link>
                    <!-- <a class="dropdown-item" href="/press" @click="hideMenu('companyMegaMenu')">Press Room</a> -->
                    <router-link class="dropdown-item" :to="{ path: '/press' }" @click.native="hideMenu('companyMegaMenu')">Press Room</router-link>
                    <!-- <a class="dropdown-item" href="/partners" @click="hideMenu('companyMegaMenu')">Partners</a> -->
                  </div>
                </li>
                <!-- End Company -->

                <!-- Blog -->
                <li class="header-nav-item">
                  <a class="nav-link header-nav-link mr-0 mr-lg-3" href="https://blog.openapihub.com/" target="_blank" @click="hideMenu('productsMegaMenu')">Blog</a>
                </li>
                <!-- End Blog -->

                <!-- Contact Us Button -->
                <li class="navbar-nav-last-item">
                  <!-- <a id="ga-header-contact-us-button" class="btn btn-sm btn-primary transition-3d-hover" href="/contact">Contact Us</a> -->
                  <router-link id="ga-header-contact-us-button" class="btn btn-sm btn-primary transition-3d-hover" :to="{ path: '/contact' }" @click.native="hideMenu('productsMegaMenu')">Contact Us</router-link>
                </li>
                <!-- End Contact Us Button -->
              </ul>
            </div>
          </div>
          <!-- End Navigation -->
        </nav>
        <!-- End Nav -->
      </div>
    </div>
  </header>
</template>

<script>
const $ = require('jquery')
import HSHeader from '../assets/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '../assets/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSGoTo from '../assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      menu_expanded: false
    }
  },
  async mounted() {
    $('#header').each(function() {
      new HSHeader($(this)).init()
    })
    $('.js-mega-menu').each(function() {
      new HSMegaMenu($(this)).init()
    })
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    scrollToFooter() {
      window.scrollTo({
        top: $('#footer').offset().top,
        behavior: 'smooth'
      })
    },
    expandMenu() {
      this.menu_expanded = !this.menu_expanded
      if (this.menu_expanded) {
        $('#navBar').slideDown('fast', function() { $('#navBar').addClass('show'); $('#navBar').attr('style', null) })
      } else {
        $('#navBar').slideUp('fast', function() { $('#navBar').removeClass('show'); $('#navBar').attr('style', null) })
      }
      // $('#navHideButton').attr('aria-expanded', 'true')
      // this.menu_expanded = !this.menu_expanded
      // console.log($('#navBar'))
      // $('#navBar').collapse('show')
    },
    hideMenu(menuLabel) {
      // const megaMenu = $("[aria-labelledby='useCasesMegaMenu']")
      // console.log(megaMenu)
      // megaMenu.css('display', 'none')
      const isMobile = $('#navHideButton').css('display') === 'inline-flex'
      if (isMobile) {
        $('#navHideButton').click()
      } else {
        $("[aria-labelledby='" + menuLabel + "']").css('display', 'none')
        $('#navHideButton').click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.css");
.dropdown-reverse {
  left: auto !important;
  right: 0px !important;
}

.force-hide {
  display: none !important;
}

#userProfileDropdown {
  top: 65px;
}
</style>
