const state = {
  showDummy: false
}

const mutations = {
  SET_SHOW_DUMMY: (state, status) => {
    state.showDummy = status
  }
}

const actions = {
  setShowDummy({ commit }, status) {
    commit('SET_SHOW_DUMMY', status)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
