const state = {
  apidays_2021_recap_list: [
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Digital Identity Centric Approach to Accelerate HKMA OpenAPI Phase3/4 Compliance',
      speaker: 'ajaybiyani',
      speaker_img: '2021/speakers/ajay-pro.jpg',
      speaker_name: 'Ajay Biyani',
      speaker_position: 'Regional Vice President, ASEAN at ForgeRock',
      video_url: 'https://www.youtube.com/watch?v=LGb1v6yowYI&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-digital-identity-centric-approach-to-accelerate-hkma-openapi-phase34-compliance-by-ajay-biyani-forgerock'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'APIs – How Did We Get Here and Where Are We Going Next?',
      speaker: 'alanglickenhouse',
      speaker_img: '2021/speakers/alang-pro.jpg',
      speaker_name: 'Alan Glickenhouse',
      speaker_position: 'Digital Transformation Business Strategist at IBM',
      video_url: 'https://www.youtube.com/watch?v=xsjHSXrPXvU&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-apis-how-did-we-get-here-and-where-are-we-going-next-by-alan-glickenhouse-ibm'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Composable Data for the Composable Enterprise - Liquefying Data Capital with APIs',
      speaker: 'andrewdent',
      speaker_img: '2021/speakers/andrew-pro.jpg',
      speaker_name: 'Andrew Dent',
      speaker_position: 'Director of Solution Engineering at Mulesoft',
      video_url: 'https://www.youtube.com/watch?v=WsESC3iSjKs&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-composable-data-for-the-composable-enterprise-by-andrew-dent-mulesoft'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'API Mocking Best Practices',
      speaker: 'archanapatel',
      speaker_img: '2021/speakers/archana-pro.jpg',
      speaker_name: 'Archana Patel',
      speaker_position: 'Customer Success Architect at Mulesoft',
      video_url: 'https://www.youtube.com/watch?v=4Tc_8eKw9AE&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-api-mocking-best-practices-by-archana-patel-mulesoft'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Building an Integrated Supply Chain for APIs',
      speaker: 'asankaabeysinghe',
      speaker_img: '2021/speakers/asanka-pro.jpg',
      speaker_name: 'Asanka Abeysinghe',
      speaker_position: 'Chief Technology Evangelist at WSO2',
      video_url: 'https://www.youtube.com/watch?v=taZbUuSdHPA&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-building-an-integrated-supply-chain-for-apis-by-asanka-abeysinghe-wso2'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'API Ecosystem and Banking Open API Phase III & IV',
      speaker: 'carlseto',
      speaker_img: '2021/speakers/carl-pro.jpg',
      speaker_name: 'Carl Seto',
      speaker_position: 'Director, Product and Marketing at JETCO',
      video_url: 'https://www.youtube.com/watch?v=pA-ZpqCYgLg&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-api-ecosystem-and-banking-open-api-phase-iii-iv-by-carl-seto-jetco'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Getting API Management Adopted: The Hearts and Minds Beyond the Technology',
      speaker: 'clairebarrett',
      speaker_img: '2021/speakers/claire-pro.jpg',
      speaker_name: 'Claire Barrett',
      speaker_position: 'Director at APIsFirst',
      video_url: 'https://www.youtube.com/watch?v=7-mvthdVDH8&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-getting-api-management-adopted-the-hearts-and-minds-beyond-the-technology-by-claire-barrett-apisfirst'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'How to Use Federated Learning to Achieve Secure, Privacy-Preserving Joint AI Model Building in Production',
      speaker: 'clivechan',
      speaker_img: '2021/speakers/clive-pro.jpg',
      speaker_name: 'Clive Chan',
      speaker_position: 'Principal Solution Architect at WeBank',
      video_url: 'https://www.youtube.com/watch?v=YD2gg1oX2Ns&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-how-to-use-federated-learning-to-achieve-secure-privacypreserving-joint-ai-model-building-in-production-by-clive-chan-webank'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Less Data is More',
      speaker: 'damirsvrtan',
      speaker_img: '2021/speakers/damir-pro.jpg',
      speaker_name: 'Damir Svrtan',
      speaker_position: 'Senior Software Engineer at Netflix',
      video_url: 'https://www.youtube.com/watch?v=g6pqsi4ruMg&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-less-data-is-more-by-damir-svrtan-netflix'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Building Trust Frameworks in Open Banking APIs',
      speaker: 'davidoneill',
      speaker_img: '2021/speakers/david-pro.jpg',
      speaker_name: 'David O\'Neill',
      speaker_position: 'CEO of APImetrics Inc',
      video_url: 'https://www.youtube.com/watch?v=LHgkk31NDiM&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Building a Solid Interoperable Foundation for Your Enterprise APIs with SUSE Rancher and Kubernetes',
      speaker: 'derekso',
      speaker_img: '2021/speakers/derek-pro.jpg',
      speaker_name: 'Derek So',
      speaker_position: 'APJ Principal Technologist - Cloud Native at SUSE',
      video_url: 'https://www.youtube.com/watch?v=ZigKoZXbD54&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-building-a-solid-interoperable-foundation-for-your-enterprise-apis-by-derek-so-suse'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'API Narrative: A True Story of APIs and I',
      speaker: 'divmanickam',
      speaker_img: '2021/speakers/div-pro.jpg',
      speaker_name: 'Div Manickam',
      speaker_position: 'Mentor & Facilitator at Women in APIs',
      video_url: 'https://www.youtube.com/watch?v=RJocL5bAnAA&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Unleash the Power of Big Data with API Collaboration',
      speaker: 'drtoacharm',
      speaker_img: '2021/speakers/toa-pro.jpg',
      speaker_name: 'Dr. Toa Charm',
      speaker_position: 'Associate Professor of Practice in Management at CUHK Business School',
      video_url: 'https://www.youtube.com/watch?v=YapkeUoKQrs&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-unleash-the-power-of-big-data-with-api-collaboration-by-dr-toa-charm-cuhk-business-school'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Building AI/ML Model API for Digital App',
      speaker: 'easonlai',
      speaker_img: '2021/speakers/eason-pro.jpg',
      speaker_name: 'Eason Lai',
      speaker_position: 'Lead Digital Solution Architect at AIA Hong Kong and Macau',
      video_url: 'https://www.youtube.com/watch?v=BAh4i1USOm0&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-building-aiml-model-api-for-digital-app-by-eason-lai-aia'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Go to Market with APIs',
      speaker: 'emmelynwang',
      speaker_img: '2021/speakers/emmelyn-pro.jpg',
      speaker_name: 'Emmelyn Wang',
      speaker_position: 'Global Business Development Lead, AWS Marketplace at Amazon',
      video_url: 'https://www.youtube.com/watch?v=vJjwTcLQKPk&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Bank-as-a-Service (BAAS): Empowering Business by Open API (Mandarin Session)',
      speaker: 'ganyong',
      speaker_img: '2021/speakers/gan-pro.jpg',
      speaker_name: 'Gan Yong',
      speaker_position: 'CIO of Consumer Banking at China Construction Bank (Asia)',
      video_url: 'https://www.youtube.com/watch?v=d2R2gDc8gzQ&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-bankasaservice-baas-empowering-business-by-open-ap-by-gan-yong-consumer-banking-at-china-construction-bank-asia'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Be the Orchestrator for Banking-as-a-Service (BaaS): A Conceptual Solution Framework for Re-Bundling of Financial Services API Products',
      speaker: 'geoffreylau',
      speaker_img: '2021/speakers/geoffrey-pro.jpg',
      speaker_name: 'Geoffrey Lau',
      speaker_position: 'Chief Solution Architect at Software AG Asia Pacific & Japan',
      video_url: 'https://www.youtube.com/watch?v=G6PW3Rw0-aU&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Enterprise Integration Patterns for OpenAPI Initiatives',
      speaker: 'hieunguyennhu',
      speaker_img: '2021/speakers/hieu-pro.jpg',
      speaker_name: 'Hieu Nguyen Nhu',
      speaker_position: 'Cloud Native Senior Technical Specialist at Microsoft',
      video_url: 'https://www.youtube.com/watch?v=W-Wh4i5sfXQ&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-enterprise-integration-patterns-for-openapi-initiatives-by-hieu-nguyen-nhu-microsoft'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Leveraging Open Banking Phase 3 & 4 and CDI to Create a Smart and Vibrant SME Ecosystem',
      speaker: 'howardwong',
      speaker_img: '2021/speakers/howard-pro.jpg',
      speaker_name: 'Howard Wong',
      speaker_position: 'Chief FinTech Evangelist at FORMSHK',
      video_url: 'https://www.youtube.com/watch?v=db-29i6baaM&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-leveraging-open-banking-phase-3-4-and-cdi-to-create-a-smart-and-vibrant-sme-ecosystem-by-howard-wong-formshk'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Event-driven APIs & Schema Governance for Apache Kafka',
      speaker: 'hugoguerrero',
      speaker_img: '2021/speakers/hugo-pro.jpg',
      speaker_name: 'Hugo Guerrero',
      speaker_position: 'APIs & Messaging Developer Advocate at Red Hat',
      video_url: 'https://www.youtube.com/watch?v=rwlHJkgaRdw&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-eventdriven-apis-schema-governance-for-apache-kafka-by-hugo-guerrero-red-hat'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Federated Learning for Banking',
      speaker: 'isaacwong',
      speaker_img: '2021/speakers/isaac-pro.jpg',
      speaker_name: 'Isaac Wong',
      speaker_position: 'AI Solution Architect at WeBank',
      video_url: 'https://www.youtube.com/watch?v=8TaA6r_ATT0&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-federated-learning-for-banking-by-isaac-wong-webank'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'DevOps is Dead',
      speaker: 'jasonesli',
      speaker_img: '2021/speakers/jason-pro.jpg',
      speaker_name: 'Jason Esli',
      speaker_position: 'Solutions Consultant at New Relic',
      video_url: 'https://www.youtube.com/watch?v=lVTJdjGHL7Q&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-devops-is-dead-by-jason-esli-new-relic'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Rethinking Financial Services with Data in Motion',
      speaker: 'jeffreylam',
      speaker_img: '2021/speakers/jeffrey-pro.jpg',
      speaker_name: 'Jeffrey Lam',
      speaker_position: 'Senior Solution Engineer, Greater China Region at Confluent',
      video_url: 'https://www.youtube.com/watch?v=sdewFg-1_ac&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-rethinking-financial-services-with-data-in-motion-by-jeffrey-lam-confluent'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Transforming the Financial Services Industry with Event Stream Data: Use Cases from APAC Region',
      speaker: 'jeffreylam-workshop',
      speaker_img: '2021/speakers/jeffrey-pro.jpg',
      speaker_name: 'Jeffrey Lam',
      speaker_position: 'Senior Solution Engineer, Greater China Region at Confluent',
      video_url: 'https://www.youtube.com/watch?v=qM8D9isfEJ8&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'How to Start a Tech Community',
      speaker: 'jenksguo',
      speaker_img: '2021/speakers/jenks-pro.jpg',
      speaker_name: 'Jenks Guo',
      speaker_position: 'Partner Solutions Lead at Linktree',
      video_url: 'https://www.youtube.com/watch?v=ydo4PgY-sJQ&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-how-to-start-a-tech-community-by-jenks-guo-linktree'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Automating the API Product Lifecycle',
      speaker: 'jeremyglassenberg',
      speaker_img: '2021/speakers/jeremy-pro.jpg',
      speaker_name: 'Jeremy Glassenberg',
      speaker_position: 'Product Lead, APIs at Docusign',
      video_url: 'https://www.youtube.com/watch?v=vHKsywTKgQk&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-automating-the-api-product-lifecycle-by-jeremy-glassenberg-docusign'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Multi-Protocol APIs at Scale in adidas',
      speaker: 'jesusdediego',
      speaker_img: '2021/speakers/jesus-pro.jpg',
      speaker_name: 'Jesus de Diego',
      speaker_position: 'API Evangelist at Adidas',
      video_url: 'https://www.youtube.com/watch?v=0zDv1VAscdg&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-multiprotocol-apis-at-scale-in-adidas-by-jesus-de-diego-adidas'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'The API Trends for 2022 and Beyond',
      speaker: 'jimmytsang',
      speaker_img: '2021/speakers/jimmy-pro.jpg',
      speaker_name: 'Jimmy Tsang',
      speaker_position: 'EASG Committee at Hong Kong Computer Society',
      video_url: 'https://www.youtube.com/watch?v=E-lnSjtsWFk&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-the-api-trends-for-2022-and-beyond-by-jimmy-tsang-hong-kong-computer-society'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Modernizing Monolith Applications with API Architecture',
      speaker: 'johnyeung',
      speaker_img: '2021/speakers/john-pro.jpg',
      speaker_name: 'John Yeung',
      speaker_position: 'EASG Committee at Hong Kong Computer Society',
      video_url: 'https://www.youtube.com/watch?v=5t81jRPmN60&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-modernizing-monolith-applications-with-api-architecture-by-john-yeung-hong-kong-computer-society'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Open Banking Development from a Regional Perspective',
      speaker: 'jonathancheung',
      speaker_img: '2021/speakers/jonathan-pro.jpg',
      speaker_name: 'Jonathan Cheung',
      speaker_position: 'Director Open Banking & API at Standard Chartered Bank',
      video_url: 'https://www.youtube.com/watch?v=aWpYmbFla5s&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-open-banking-development-from-a-regional-perspective-by-jonathan-cheung-standard-chartered-bank'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Are Events Just Another Type of API? If So, How Should We Expose Them?',
      speaker: 'kimclark',
      speaker_img: '2021/speakers/kim-pro.jpg',
      speaker_name: 'Kim Clark',
      speaker_position: 'Integration Architect at IBM ',
      video_url: 'https://www.youtube.com/watch?v=Zk0G4XGGJec&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Zero Trust Security with Service Mesh',
      speaker: 'laurentpetroque',
      speaker_img: '2021/speakers/laurent-pro.jpg',
      speaker_name: 'Laurent Petroque',
      speaker_position: 'Director Solutions Engineering at F5 Networks',
      video_url: 'https://www.youtube.com/watch?v=uu8xSAXbOaA&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-zero-trust-security-with-service-mesh-by-laurent-petroque-f5-networks'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'How to Achieve Zero-Trust Security With Kuma Service Mesh',
      speaker: 'marcopalladino',
      speaker_img: '2021/speakers/marco-pro.jpg',
      speaker_name: 'Marco Palladino',
      speaker_position: 'CTO and Co-Founder of Kong Inc.',
      video_url: 'https://www.youtube.com/watch?v=P9TV3geznoY&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Planning Your Steps to Data Economy Using APIOps Cycles',
      speaker: 'marjukkaniinioja',
      speaker_img: '2021/speakers/marjukka-pro.jpg',
      speaker_name: 'Marjukka Niinioja',
      speaker_position: 'Founding Partner at Osaango Ltd',
      video_url: 'https://www.youtube.com/watch?v=3_3E69OboGg&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-planning-your-steps-to-data-economy-using-apiops-cycles-by-marjukka-niinioja-osaango'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'How to Organize a Hackathon That Really Stands Out',
      speaker: 'mathieupincovai',
      speaker_img: '2021/speakers/mathieu-pro.jpg',
      speaker_name: 'Mathieu Pincovai',
      speaker_position: 'Customer Success Specialist at Amadeus',
      video_url: 'https://www.youtube.com/watch?v=fAZ8BsRzXDs&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-how-to-organize-a-hackathon-that-really-stands-out-by-mathieu-pincovai-amadeus'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'The Big Rebundling in Financial Services: Threats and Opportunities of Banking-as-a-Service (BaaS)',
      speaker: 'matthiasbiehl',
      speaker_img: '2021/speakers/matthias-pro.jpg',
      speaker_name: 'Matthias Biehl',
      speaker_position: 'API Strategist at Software AG',
      video_url: 'https://www.youtube.com/watch?v=_5b63nyNVSc&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-the-big-rebundling-in-financial-services-by-matthias-biehl-software-ag'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: '20 Minutes to Build a Serverless COVID-19 REST and GraphQL APIs',
      speaker: 'maximebeugnet',
      speaker_img: '2021/speakers/maximeb-pro.jpg',
      speaker_name: 'Maxime Beugnet',
      speaker_position: 'Senior Developer Advocate at MongoDB',
      video_url: 'https://www.youtube.com/watch?v=LvKterv9kFA&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'GraphQL: Beyond APIs, Graph Your Enterprise',
      speaker: 'maximetopolov',
      speaker_img: '2021/speakers/maximet-pro.jpg',
      speaker_name: 'Maxime Topolov',
      speaker_position: 'CEO of GraphQL Portal',
      video_url: 'https://www.youtube.com/watch?v=Wl-WegA6l7I&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-graphql-beyond-apis-graph-your-enterprise-by-maxime-topolov-graphql-portal'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'The State of the API Industry: Tech, Business and Regulations',
      speaker: 'mehdimedjaoui',
      speaker_img: '2021/speakers/mehdi-pro.jpg',
      speaker_name: 'Mehdi Medjaoui',
      speaker_position: 'Founder of apidays conferences',
      video_url: 'https://www.youtube.com/watch?v=l1uVzcANPzA&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Securing GraphQL with Cost Directives',
      speaker: 'morrismatsa',
      speaker_img: '2021/speakers/morris-pro.jpg',
      speaker_name: 'Morris Matsa',
      speaker_position: 'APIGW Lead at IBM',
      video_url: 'https://www.youtube.com/watch?v=huuIR4s8Ifg&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Building Event-Driven APIs',
      speaker: 'naveennandan',
      speaker_img: '2021/speakers/naveen-pro.jpg',
      speaker_name: 'Naveen Nandan',
      speaker_position: 'Senior Solutions Engineer at Confluent APAC',
      video_url: 'https://www.youtube.com/watch?v=96XNjqCEMGI&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Next Stage for Open API at Banking Industry',
      speaker: 'nickyng',
      speaker_img: '2021/speakers/nicky-pro.jpg',
      speaker_name: 'Nicky Ng',
      speaker_position: 'Architect at IBM',
      video_url: 'https://www.youtube.com/watch?v=weKLsKjV6XE&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-next-stage-for-open-api-at-banking-industry-by-nicky-ng-ibm'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Better API DX with a CLI',
      speaker: 'philnash',
      speaker_img: '2021/speakers/phil-pro.jpg',
      speaker_name: 'Phil Nash',
      speaker_position: 'Developer Evangelist at Twilio',
      video_url: 'https://www.youtube.com/watch?v=NnrIMxGFdB8&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-better-api-dx-with-a-cli-by-phil-nash-twilio'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Test Data 101 for APIs',
      speaker: 'pricillabilavendran',
      speaker_img: '2021/speakers/pricilla-pro.jpg',
      speaker_name: 'Pricilla Bilavendran',
      speaker_position: 'Senior QA Enginner at Billennium IT Services (M) Sdn',
      video_url: 'https://www.youtube.com/watch?v=1WH08kTBIYw&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-test-data-101-for-apis-by-pricilla-bilavendran-billennium-it-services'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Driving Digital Customer Acquisition with Open Banking',
      speaker: 'ranaperies',
      speaker_img: '2021/speakers/rana-pro.jpg',
      speaker_name: 'Rana Peries',
      speaker_position: 'CTO of 101 Digital',
      video_url: 'https://www.youtube.com/watch?v=n5zXwaXbS1k&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-driving-digital-customer-acquisition-with-open-banking-by-rana-peries-101-digital'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'APIs – Trust, Commerce and Connection',
      speaker: 'richardlord',
      speaker_img: '2021/speakers/richard-pro.jpg',
      speaker_name: 'Richard Lord',
      speaker_position: 'Chief Information Officer, Commercial Banking – Asia Pacific at HSBC Hong Kong',
      video_url: 'https://www.youtube.com/watch?v=o5g69J31uG8&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-apis-trust-commerce-and-connection-by-richard-lord-hsbc-hong-kong'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Shifting Out of Triage Mode with a Failing API',
      speaker: 'robdickinson',
      speaker_img: '2021/speakers/rob-pro.jpg',
      speaker_name: 'Rob Dickinson',
      speaker_position: 'CTO and Co-founder of Resurface Labs Inc.',
      video_url: 'https://www.youtube.com/watch?v=74-cC_3p61k&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-shifting-out-of-triage-mode-with-a-failing-api-by-rob-dickinson-resurface-labs'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Confessions of a Product Geek: My First API',
      speaker: 'rosemarymissier',
      speaker_img: '2021/speakers/rosemary-pro.jpg',
      speaker_name: 'Rosemary Missier',
      speaker_position: 'Product Manager at Xero',
      video_url: 'https://www.youtube.com/watch?v=AXH2tZlODTc&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Hybrid API Management Strategies',
      speaker: 'shiroshicakulatilake',
      speaker_img: '2021/speakers/shiroshica-pro.jpg',
      speaker_name: 'Shiroshica Kulatilake',
      speaker_position: 'Director - Solutions Architecture at WSO2',
      video_url: 'https://www.youtube.com/watch?v=PYRumNnJARI&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'API Economy in Financial Services',
      speaker: 'siddhantagarwal',
      speaker_img: '2021/speakers/siddhant-pro.jpg',
      speaker_name: 'Siddhant Agarwal',
      speaker_position: 'Developer Relations Lead at Zwitch.io',
      video_url: 'https://www.youtube.com/watch?v=iD_2M1_-pO4&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-api-economy-in-financial-services-by-siddhant-agarwal-zwitchio'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Docs, Codegen, Discovery & Governance in a Subscribe/Notify World',
      speaker: 'simenfossnes',
      speaker_img: '2021/speakers/simen-pro.jpg',
      speaker_name: 'Simen Fossnes',
      speaker_position: 'CTO & Co-Founder of Diggit',
      video_url: 'https://www.youtube.com/watch?v=CH6t1gshXpo&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'TradFi and DeFi or, Where the Land Meets the Sea',
      speaker: 'simonredfern',
      speaker_img: '2021/speakers/simon-pro.jpg',
      speaker_name: 'Simon Redfern',
      speaker_position: 'CEO and Founder of TESOBE',
      video_url: 'https://www.youtube.com/watch?v=E8tA1napAic&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-tradfi-and-defi-or-where-the-land-meets-the-sea-by-simon-redfern-tesobe'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Managing Multiple APIs Across Any Environment with MuleSoft',
      speaker: 'simonwong',
      speaker_img: '2021/speakers/simonw-pro.jpg',
      speaker_name: 'Simon Wong',
      speaker_position: 'Principal Strategic Client Architect at Mulesoft',
      video_url: 'https://www.youtube.com/watch?v=kqBS3J1YsNY&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Headless API Management',
      speaker: 'snehalchakraborty',
      speaker_img: '2021/speakers/snehal-pro.jpg',
      speaker_name: 'Snehal Chakraborty',
      speaker_position: 'Cloud Integration Architect at Accenture Netherlands B.V.',
      video_url: 'https://www.youtube.com/watch?v=gZ22GaBslqE&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-headless-api-management-by-snehal-chakraborty-accenture-netherlands'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Observing What\'s Powering Your APIs',
      speaker: 'stuartseow',
      speaker_img: '2021/speakers/stuart-pro.jpg',
      speaker_name: 'Stuart Seow',
      speaker_position: 'Senior Solutions Consultant at New Relic',
      video_url: 'https://www.youtube.com/watch?v=f_AOKPxQoVY&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'OAS to Managed API in Seconds',
      speaker: 'tharikamadurapperuma',
      speaker_img: '2021/speakers/tharika-pro.jpg',
      speaker_name: 'Tharika Madurapperuma',
      speaker_position: 'Senior Software Engineer at WSO2',
      video_url: 'https://www.youtube.com/watch?v=VCliq-y8YW4&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Dissecting the Myths of API',
      speaker: 'trevorcheung',
      speaker_img: '2021/speakers/trevor-pro.jpg',
      speaker_name: 'Trevor Cheung',
      speaker_position: 'MD, Head of Digital & Ecosystems & Innovation, Institutional Banking Group at DBS Hong Kong and GBA',
      video_url: 'https://www.youtube.com/watch?v=E2vgvIi46Vs&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-2021-dissecting-the-myths-of-api-by-trevor-cheung-dbs-hong-kong-and-gba'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'APIs in Healthcare – Tales From the Field',
      speaker: 'ulihitzel',
      speaker_img: '2021/speakers/uli-pro.jpg',
      speaker_name: 'Uli Hitzel',
      speaker_position: 'Chief Architect at Care and Advisor at Raffles Medical Group',
      video_url: 'https://www.youtube.com/watch?v=-1ODlwZKyBI&list=PLmEaqnTJ40OrND8VYk-I631D15K7V-UpQ',
      slide_url: ''
    }
  ]
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

