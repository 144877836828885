<template>
  <div id="contact-us" class="bg-navy position-relative">
    <div class="container position-relative z-index-2">
      <!-- ========== Info ========== -->
      <div class="space-2">
        <div class="row justify-content-lg-between">
          <div class="col-lg-4 ml-lg-auto mb-5 mb-lg-0">
            <div class="mb-4">
              <router-link class="nav-link" :to="{ path: '/' }">
                <img class="brand" src="@/assets/svg/logos/logo-white.svg">
              </router-link>
            </div>
            <!-- <ul class="nav nav-sm nav-x-0 nav-white flex-column">
              <li class="nav-item">
                <div class="nav-link media">
                  <span class="media">
                    <span class="fas fa-location-arrow mt-1 mr-2" />
                    <span class="media-body">
                      <a href="https://goo.gl/maps/qRucKAtMNeCWpb7VA" target="_blank" class="text-white-70">
                        Unit 320, 3/F, Building 16W, Phase 3<br>
                        No. 16 Science Park West Avenue<br>
                        Hong Kong Science Park<br>
                        Shatin, N.T., Hong Kong
                      </a>
                    </span>
                  </span>
                </div>
              </li>
              <li class="nav-item">
                <div class="nav-link media">
                  <span class="media">
                    <span class="fas fa-envelope mt-1 mr-2" />
                    <span class="media-body">
                      <a href="mailto: info@beNovelty.com" class="text-white-70">info@beNovelty.com</a>
                    </span>
                  </span>
                </div>
              </li>
              <li class="nav-item">
                <div class="nav-link media">
                  <span class="media">
                    <span class="fas fa-phone-alt mt-1 mr-2" />
                    <span class="media-body">
                      <a href="tel:+852 3598-3658" class="text-white-70">+852 3598-3658</a>
                    </span>
                  </span>
                </div>
              </li>
            </ul> -->
          </div>

          <div class="col-6 col-md-3 col-lg mb-5 mb-md-0">
            <h5 class="text-white font-weight-bold">Solutions</h5>
            <ul class="nav nav-sm nav-x-0 nav-white flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-first-architecture' }">API Gateway and Infrastructure Development</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-product' }" @click.native="hideMenu('solutionsMegaMenu')">API and Micro-Services Development</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-community' }" @click.native="hideMenu('solutionsMegaMenu')">API Strategy and Ecosystem Development</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-education/lego-serious-play' }" @click.native="hideMenu('solutionsMegaMenu')">API-First Training & Hackathon</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">AI Gateway and Infrastructure</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">AI Agents Development</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/ai-agent' }" @click.native="hideMenu('solutionsMegaMenu')">RAG, LLM Fine Tuning and Guardrails Implementation</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-consultancy' }">API Consultancy</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/ai-gateway' }">AI Gateway</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-product' }">API Product</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-management' }">API Management</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-management/kong-enterprise-api-solution' }">Enterprise API Solution (Kong Konnect)</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-community' }">API Community</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/solutions/api-education' }">API Education</router-link>
              </li> -->
            </ul>
          </div>

          <div class="col-6 col-md-3 col-lg mb-5 mb-md-0">
            <h5 class="text-white font-weight-bold">Products</h5>
            <ul class="nav nav-sm nav-x-0 nav-white flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/products/fabrixapi' }">FabriXAPI</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/products/openapihub' }">OpenAPIHub</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/products/fiberconnect' }">FiberConnect</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/products/fabrixai' }">FabriXAI</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ path: '/products/taptab' }">TapTab</router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-3 col-lg mb-5 mb-md-0">
            <h5 class="text-white font-weight-bold">Resources</h5>
            <ul class="nav nav-sm nav-x-0 nav-white flex-column">
              <li class="nav-item">
                <!-- <a class="nav-link" href="/events">Events</a> -->
                <router-link class="nav-link" :to="{ path: '/events' }">Events</router-link>
              </li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="/resource-library">Resource Library</a> -->
                <router-link class="nav-link" :to="{ path: '/resource-library' }">Resource Library</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://blog.openapihub.com/" target="_blank">Blog</a>
              </li>
            </ul>
          </div>

          <div class="col-6 col-md-3 col-lg">
            <h5 class="text-white font-weight-bold">Company</h5>
            <ul class="nav nav-sm nav-x-0 nav-white flex-column">
              <li class="nav-item">
                <!-- <a class="nav-link" href="/about">About</a> -->
                <router-link class="nav-link" :to="{ path: '/about' }">About</router-link>
              </li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="/trust">Trust</a> -->
                <router-link class="nav-link" :to="{ path: '/trust' }">Trust</router-link>
              </li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="/careers">Careers</a> -->
                <router-link class="nav-link" :to="{ path: '/careers' }">Careers</router-link>
              </li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="/press">Press Room</a> -->
                <router-link class="nav-link" :to="{ path: '/press' }">Press Room</router-link>
              </li>
              <!-- <li class="nav-item"><a class="nav-link" href="/partners">Partners</a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- ========== End Info ========== -->

      <hr class="opacity-xs my-0">

      <!-- ========== Terms & Copyright ========== -->
      <div class="space-2">
        <div class="row align-items-md-center mb-7">
          <!-- Terms -->
          <div class="col-md-6 mb-4 mb-md-0 z-index-999">
            <ul class="nav nav-sm nav-white nav-x-sm align-items-center">
              <li class="nav-item">
                <!-- <a class="nav-link" href="/terms-of-use">Terms of Use</a> -->
                <router-link class="nav-link" :to="{ path: '/terms-of-use' }">Terms of Use</router-link>
              </li>
              <li class="nav-item opacity mx-3 text-white">/</li>
              <li class="nav-item">
                <!-- <a class="nav-link" href="/privacy-policy">Privacy Policy</a> -->
                <router-link class="nav-link" :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
          <!-- End Terms -->

          <!-- Social Networks -->
          <div class="col-md-6 text-md-right z-index-999">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-light" href="mailto: info@beNovelty.com">
                  <i class="fas fa-envelope" />
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-light" href="https://www.facebook.com/beNovelty" target="_blank">
                  <i class="fab fa-facebook-f" />
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn btn-xs btn-icon btn-soft-light" href="https://www.linkedin.com/company/benovelty/" target="_blank">
                  <i class="fab fa-linkedin" />
                </a>
              </li>
            </ul>
          </div>
          <!-- End Social Networks -->
        </div>

        <!-- Copyright -->
        <div class="w-md-75 text-lg-center mx-lg-auto z-index-999">
          <p class="text-white opacity-sm small">© 2015-{{ new Date().getFullYear() }} All Rights Reserved by beNovelty Limited.</p>
        </div>
        <!-- End Copyright -->
      </div>
      <!-- ========== End Terms & Copyright ========== -->
    </div>

    <!-- SVG Background Shape -->
    <figure class="w-100 position-absolute bottom-0 left-0">
      <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
    </figure>
    <!-- End SVG Background Shape -->

    <!-- ========== Popup Modals ========== -->
    <!-- Hubspot Form - beNovelty General Inquiry Modal -->
    <div id="benGeneralForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="benGeneralFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="benGeneralFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <!--[if lte IE 8]>
                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
                <![endif]-->
              <form ref="benGeneralForm" />
              <!-- <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
              <script>
                hbspt.forms.create({
                portalId: "7091000",
                formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
                });
              </script> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hubspot Form - beNovelty General Inquiry Modal -->
    <!-- ========== End Popup Modals ========== -->
  </div>
</template>

<script>
// import 'bootstrap'
export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  }
}
</script>
