const state = {
  lang: 'en'
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

