const state = {
  event_list: [
    {
      url: 'events/apidays-hongkong-2023',
      image_url: 'thumbnails/apidays-hongkong-2023.jpg',
      category_title: 'Conference',
      category: 'Conference',
      title: 'Apidays Hong Kong 2023',
      description: 'The Biggest API-First Conference in Hong Kong',
      date: 'Late August, 2023',
      time: 'TBA'
    },
    {
      url: 'events/apidays-live-hongkong-2022',
      image_url: 'thumbnails/apidays-live-hongkong-2022.jpg',
      category_title: 'Conference',
      category: 'Conference',
      title: 'Apidays Hong Kong 2022',
      description: 'API-First Digital Transformation & Platform Economy',
      date: 'Aug 24, 2022 (Wednesday) & Aug 25, 2022 (Thursday)',
      time: '9:00am - 6:00pm (HKT)',
      status: 'Event Ended'
    },
    {
      url: 'events/20211129-steps-to-build-your-successful-ecommerce-kingdom',
      image_url: 'events/webinars/banners/20211209-steps-to-build-your-successful-ecommerce-kingdom.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '2022疫後新契機 — 打造電商王國的致勝關鍵',
      description: '分享電子商務的行銷攻略，讓你迅速掌握最新的數碼營銷趨勢，並了解到如何利用銀行API及FPS轉數快系統令收款流程更順暢',
      date: 'Dec 9, 2021 (Thursday)',
      time: '2:30pm - 3:30pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended. The webinar materials are now available on-demand!',
      website: 'https://us02web.zoom.us/webinar/register/1316317801047/WN_TRP4PiT7Stymsez99iBbXg',
      button_text: 'Learn More',
      more_card: [
        {
          title: 'Watch This Webinar Now',
          description: 'If you missed this webinar, don\'t worry! The recording and presentation slide of the webinar are now available on-demand.',
          button_text: 'Watch Now',
          image_url: 'replay-video.svg',
          resource_url: 'https://hub.openapihub.com/member/community-updates/20211209-The-Key-to-Success-in-Building-an-E-commerce-Empire/resources'
        }
      ],
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'DBS',
          image_url: 'webinars/organisers/dbs.png'
        },
        {
          company: 'Migather',
          image_url: 'webinars/organisers/migather.png'
        },
        {
          company: 'Solution Forest',
          image_url: 'webinars/organisers/solution_forest.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [],
      about_special: '<p>自疫情爆發至今，網購商機逆勢增長。根據統計數字顯示，香港去年電商營業額高達590億港元，年度增長超過18%，同時預計網購熱潮將延續至2022年。不論你是中小企店主，還是有意發展網上生意，是時候計劃新一年的電商策略，搶佔商機！是次網上研討會邀請到銀行、科技服務及數碼營銷的講者，一起分享電子商務的行銷攻略，讓你迅速掌握最新的數碼營銷趨勢，並了解到如何利用銀行API及FPS轉數快系統令收款流程更順暢。</p><p>*首50位登記可享特別優惠。優惠詳情將於網上研討會內公佈，萬勿錯過！</p>',
      agenda: [
        '電子商務 + 網上營銷 = 無限商機',
        '從0到1 — 創建你的電商業務',
        '網店支付新趨勢 — 轉數快（FPS）',
        '如何利用轉數快（FPS）提升網店營運效率'
      ],
      speaker: [
        {
          name: 'Mr. Alan Lam',
          job_title: 'Solution Forest 聯合創始人兼董事',
          bio: '熱衷於科技與生活的連結，將兩者結合的構想可能性具體實現。為初創及企業提供電子商務項目顧問, 技術設計及開發團隊領導。'
        },
        {
          name: 'Mr. Mio Chan',
          job_title: 'Migather 總監',
          bio: '超過十年廣告及數碼營銷背景；擁有4As廣告代理公司及企業內部營銷經驗；服務過70多個跨地域客戶，處理過百餘個品牌；專注於績效營銷方面，著重為客戶創造銷售額及用戶開發。'
        },
        {
          name: 'Mr. Patrick Fan',
          job_title: '彼樂科創 (beNovelty) 創辦人',
          bio: 'Patrick 是彼樂科創的創辦人和從事開放 API 及開放 Web 技術的企業家。彼樂科創是一家屢獲殊榮的 API 公司，幫助企業在 API 經濟時代開展創新和開放的業務。'
        },
        {
          name: 'Mr. Pony Lau',
          job_title: '星展香港企業及機構銀行副總裁',
          bio: 'Pony 現為星展香港企業及機構銀行副總裁，劉先生備有十年銀行經驗，主力策劃建構生態合作夥伴網絡及協助企業客戶推動數碼轉型。'
        }
      ],
      organiser_about: '<li class="mb-3"><a href="https://www.benovelty.com/" target="_blank">彼樂科創(beNovelty)</a>是一家屢獲殊榮的API技術公司，並專注於新興API平台技術，正積極推動跨行業和跨領域的API開發人員社群發展。我們建立的雲端式API平台幫助企業實現嶄新及可擴展的API商業模式，協助企業設計，管理，保護和商業化API，客戶應用包括銀行（開放式銀行），保險，酒店和旅遊，教育，醫療保健，餐飲等。FiberConnect是由beNovelty和FiberAPI共同建基於開放API(Open API)的產品，旨在通過 API 對接，協助中小企及初創企業在開立帳單、電子支付、財務會計以及發放薪金等範疇提高營運效率。</li><li class="mb-3"><a href="https://www.dbs.com.hk/sme/default.page" target="_blank">星展集團</a>是亞洲最大的金融服務集團之一，業務遍及18個市場。總部設於新加坡並於當地上市，星展業務覆蓋亞洲三大增長主軸，即大中華、東南亞和南亞地區。星展資本充裕，所取得的AA-和Aa1級信貸評級位列全球最高級別之一。星展集團在亞洲提供包括零售銀行、中小企業銀行及大型企業銀行的全面金融服務。生於亞洲、長於亞洲，星展洞悉在亞洲這個充滿活力的市場經營業務的秘訣。</li>',
      meta_content: '是次網上研討會邀請到銀行、科技服務及數碼營銷的講者，一起分享電子商務的行銷攻略，讓你迅速掌握最新的數碼營銷趨勢，並了解到如何利用銀行API及FPS轉數快系統令收款流程更順暢。',
      canonical: 'https://hub.openapihub.com/community-updates/20211209-The-Key-to-Success-in-Building-an-E-commerce-Empire'
    },
    {
      url: 'events/20211029-local-startup-experience-sharing',
      image_url: 'events/webinars/banners/20211029-local-startup-experience-sharing.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '香港初創企業的質量發展及業務可持續性策略研究 – 分享研討會',
      description: '財務管理、信息安全及品質保證是本地初創企業最常面臨的挑戰。我們誠邀各初創企業家一同參與研討會，分享見解、經驗及趨勢',
      date: 'Oct 29, 2021 (Friday)',
      time: '2:30pm - 5:30pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'Hong Kong Society for Quality',
          image_url: 'webinars/organisers/hong_kong_society_for_quality.png'
        },
        {
          company: 'HKQAA',
          image_url: 'webinars/organisers/hkqaa.png'
        }
      ],
      location: 'InnoCentre',
      language: 'Cantonese',
      fee: 'Free',
      about: [
        '財務管理、信息安全及品質保證是本地初創企業最常面臨的挑戰。我們誠邀各初創企業家一同參與研討會，分享見解、經驗及趨勢，把握機會與業界人士交流想法，建立聯繫並獲得新商機。'
      ],
      agenda: [
        '「One-stop API 平台服務 – 連接數碼業務」本地初創企業成功個案經驗分享'
      ],
      speaker: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        }
      ],
      meta_content: '財務管理、信息安全及品質保證是本地初創企業最常面臨的挑戰。我們誠邀各初創企業家一同參與研討會，分享見解、經驗及趨勢，把握機會與業界人士交流想法，建立聯繫並獲得新商機。',
      canonical: 'https://hub.openapihub.com/community-updates/20211029-A-Study-on-Quality-Development-and-Business-Sustainability-Strategies-for-Hong-Kong-Start-ups-Sharing-Seminar'
    },
    {
      url: 'events/20211021-benovelty-dbs-xero',
      image_url: 'events/webinars/banners/20211021-benovelty-dbs-xero.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '雲端會計 x 金融科技 : 如何透過轉數快令收款會計流程自動化？',
      description: '分享如何透過雲端會計軟件的轉數快收款功能，輕鬆管理企業收款會計流程',
      date: 'Oct 21, 2021 (Thursday)',
      time: '3:00pm - 4:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      website: 'https://bit.ly/benovelty-dbs-xero-webinar-reg-0934',
      button_text: 'Learn More',
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'DBS',
          image_url: 'webinars/organisers/dbs.png'
        },
        {
          company: 'Xero',
          image_url: 'webinars/organisers/xero.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [],
      about_special: '<p>妥善的財務管理對中小企尤其重要，然而將收款和會計的流程無縫對接往往是中小企的一大課題。但若能有效善用各種實用工具，企業便可以輕鬆實現財務管理自動化，同時方便追蹤公司的現金流。</p><p>有見及此，<a href="https://www.benovelty.com/" target="_blank">beNovelty彼樂科創</a>特意舉辦網上研討會，聯同<a href="https://www.dbs.com.hk/sme/default.page" target="_blank">星展銀行</a>以及<a href="https://www.xero.com/hk/" target="_blank">Xero</a>分享如何輕鬆管理企業收款會計流程，更會即場演示雲端會計軟件的<a href="https://fiberapi.com/zh/xero-payment-plugin/" target="_blank">轉數快收款功能</a>*。</p><p>*首50位登記可享<a href="https://fiberapi.com/en/contact-2/" target="_blank">特別優惠</a>。優惠詳情將於網上研討會內公佈，請勿錯過！</p>',
      agenda: [
        '中小企日常處理帳單收款的痛點',
        '星展商業版轉數快及API的實際操作和應用例子',
        '如何通過Xero雲端會計軟件更有效地處理帳單收款 (即場演示)'
      ],
      host: [
        {
          name: 'Mr. Anthony Lai',
          job_title: 'Business Partnership Manager, beNovelty',
          bio: 'Anthony 現為beNovelty的業務合作夥伴經理，亦是FiberAPI的共同創始人。 他致力於推動開放API技術和開放銀行，協助中小企及初創企業在開立帳單、電子支付、財務會計以及發放薪金等範疇提高營運效率。'
        }
      ],
      speaker: [
        {
          name: 'Mr. Pony Lau',
          job_title: 'Vice President, Ecosystem, Institutional Banking Group, DBS (Hong Kong) Limited',
          bio: 'Pony 現為星展香港企業及機構銀行副總裁，劉先生備有十年銀行經驗，主力策劃建構生態合作夥伴網絡及協助企業客戶推動數碼轉型。'
        },
        {
          name: 'Ms. Hauyee',
          job_title: 'Account Manager, Xero Asia',
          bio: 'Hauyee 是 Xero 香港的客戶經理。致力為各會計師分享有關雲端會計及各種生態系統應用程式的資訊，協助他們實踐數碼轉型，從而使一眾會計師能於雲端會計及系統應用範疇上，成為亞洲各地中小企業的首選商業顧問。在加入 Xero 之前，Hauyee 在一家香港會計師事務所擔任高級項目經理。 Hauyee 對 Xero 及其生態系統應用程式瞭如指掌，更具有電子商務、零售、貿易和餐飲行業的專業知識。通過她的諮詢和培訓工作，Hauyee 已幫助眾多香港中小企實施和連接各雲端應用程序，提升它們的效率及競爭力，取得成功。'
        }
      ],
      organiser_about: '<li class="mb-3"><a href="https://www.benovelty.com/" target="_blank">彼樂科創(beNovelty)</a>是一家屢獲殊榮的API技術公司，並專注於新興API平台技術，正積極推動跨行業和跨領域的API開發人員社群發展。我們建立的雲端式API平台幫助企業實現嶄新及可擴展的API商業模式，協助企業設計，管理，保護和商業化API，客戶應用包括銀行（開放式銀行），保險，酒店和旅遊，教育，醫療保健，餐飲等。FiberConnect是由beNovelty和FiberAPI共同建基於開放API(Open API)的產品，旨在通過 API 對接，協助中小企及初創企業在開立帳單、電子支付、財務會計以及發放薪金等範疇提高營運效率。</li><li class="mb-3"><a href="https://www.dbs.com.hk/sme/default.page" target="_blank">星展集團</a>是亞洲最大的金融服務集團之一，業務遍及18個市場。總部設於新加坡並於當地上市，星展業務覆蓋亞洲三大增長主軸，即大中華、東南亞和南亞地區。星展資本充裕，所取得的AA-和Aa1級信貸評級位列全球最高級別之一。星展集團在亞洲提供包括零售銀行、中小企業銀行及大型企業銀行的全面金融服務。生於亞洲、長於亞洲，星展洞悉在亞洲這個充滿活力的市場經營業務的秘訣。</li><li><a href="https://www.xero.com/hk/" target="_blank">Xero</a>為小型企業提供會計雲端軟件服務，在全球擁有超過270萬名用戶。小型企業僱主及他們的業務顧問能透過Xero隨時隨地在任何裝置獲取實時財務數據。Xero提供一個整合超過1,000款應用程式及連接至超過300間銀行或金融機構的生態系統。在2020及2021年，Xero被納入彭博性別平等指數。在IDC MarketScape 2020年度的供應商評核中，Xero獲認可為全球軟體即服務商及中小企雲端會計與財務應用程式的領導者。</li>',
      meta_content: 'beNovelty彼樂科創特意舉辦網上研討會，聯同星展銀行(香港)以及Xero分享: 如何透過雲端會計軟件的轉數快收款功能，輕鬆管理企業收款會計流程。',
      canonical: 'https://hub.openapihub.com/community-updates/20211021-Cloud-Accounting-x-FinTech-How-to-Automate-Accounting-Processes-Using-FPS-Payment-Collection'
    },
    {
      url: 'events/apidays-live-hongkong-2021',
      image_url: 'thumbnails/apidays-live-hongkong-2021.jpg',
      category_title: 'Conference',
      category: 'Conference',
      title: 'Apidays Live Hong Kong 2021',
      description: 'API Ecosystem & Data Interchange',
      date: 'Aug 25, 2021 (Wednesday) & Aug 26, 2021 (Thursday)',
      time: '9:00am - 6:00pm (HKT)',
      status: 'Event Ended'
    },
    {
      url: 'events/20210812-benovelty-dbs-xero',
      image_url: 'events/webinars/banners/20210812-benovelty-dbs-xero.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '雲端會計 x 金融科技 : 如何透過轉數快令收款會計流程自動化？',
      description: '分享如何透過雲端會計軟件的轉數快收款功能，輕鬆管理企業收款會計流程',
      date: 'Aug 12, 2021 (Thursday)',
      time: '2:00pm - 3:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended. The webinar materials are now available on-demand!',
      website: 'https://us02web.zoom.us/webinar/register/WN_L3TUMsK9TgCQf_l5-2fSnA',
      button_text: 'Learn More',
      more_card: [
        {
          title: 'Watch This Webinar Now',
          description: 'If you missed this webinar, don\'t worry! The recording and presentation slide of the webinar are now available on-demand.',
          button_text: 'Watch Now',
          image_url: 'replay-video.svg',
          resource_url: 'https://hub.openapihub.com/member/community-updates/20210812-Cloud-Accounting-x-FinTech-How-to-Automate-Accounting-Processes-Using-FPS-Payment-Collection/resources'
        }
      ],
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'DBS',
          image_url: 'webinars/organisers/dbs.png'
        },
        {
          company: 'Xero',
          image_url: 'webinars/organisers/xero.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [],
      about_special: '<p>妥善的財務管理對中小企尤其重要，然而將收款和會計的流程無縫對接往往是中小企的一大課題。但若能有效善用各種實用工具，企業便可以輕鬆實現財務管理自動化，同時方便追蹤公司的現金流。</p><p>有見及此，<a href="https://www.benovelty.com/" target="_blank">beNovelty彼樂科創</a>特意舉辦網上研討會，聯同<a href="https://www.dbs.com.hk/sme/default.page" target="_blank">星展銀行</a>以及<a href="https://www.xero.com/hk/" target="_blank">Xero</a>分享如何輕鬆管理企業收款會計流程，更會即場演示雲端會計軟件的<a href="https://fiberapi.com/zh/xero-payment-plugin/" target="_blank">轉數快收款功能</a>*。</p><p>*首50位登記可享<a href="https://fiberapi.com/en/contact-2/" target="_blank">特別優惠</a>。優惠詳情將於網上研討會內公佈，請勿錯過！</p>',
      agenda: [
        '中小企日常處理帳單收款的痛點',
        '星展商業版轉數快及API的實際操作和應用例子',
        '如何通過Xero雲端會計軟件更有效地處理帳單收款 (即場演示)'
      ],
      host: [
        {
          name: 'Mr. Anthony Lai',
          job_title: 'Business Partnership Manager, beNovelty',
          bio: 'Anthony 現為beNovelty的業務合作夥伴經理，亦是FiberAPI的共同創始人。 他致力於推動開放API技術和開放銀行，協助中小企及初創企業在開立帳單、電子支付、財務會計以及發放薪金等範疇提高營運效率。'
        }
      ],
      speaker: [
        {
          name: 'Mr. Pony Lau',
          job_title: 'Vice President, Ecosystem, Institutional Banking Group, DBS (Hong Kong) Limited',
          bio: 'Pony 現為星展香港企業及機構銀行副總裁，劉先生備有十年銀行經驗，主力策劃建構生態合作夥伴網絡及協助企業客戶推動數碼轉型。'
        },
        {
          name: 'Mr. Andrew Yung',
          job_title: 'Senior Account Manager, Xero',
          bio: 'Andrew 任職於Xero香港並擔任高級客戶經理，他積極推動企業數碼化轉型及為亞洲企業帶來最新的雲端應用顧問服務。Andrew是香港會計師公會的註冊資深會員(FCPA)、澳洲會計師公會會員(CPA Aust.)，以及青年領袖和註冊會計師委員會成員之一。他還熱衷於幫助初創企業，是泛亞創業投資平台 (PAVD) 的顧問，專門協助初創企業融資。'
        }
      ],
      organiser_about: '<li class="mb-3"><a href="https://www.benovelty.com/" target="_blank">彼樂科創(beNovelty)</a>是一家屢獲殊榮的API技術公司，並專注於新興API平台技術，正積極推動跨行業和跨領域的API開發人員社群發展。我們建立的雲端式API平台幫助企業實現嶄新及可擴展的API商業模式，協助企業設計，管理，保護和商業化API，客戶應用包括銀行（開放式銀行），保險，酒店和旅遊，教育，醫療保健，餐飲等。FiberConnect是由beNovelty和FiberAPI共同建基於開放API(Open API)的產品，旨在通過 API 對接，協助中小企及初創企業在開立帳單、電子支付、財務會計以及發放薪金等範疇提高營運效率。</li><li class="mb-3"><a href="https://www.dbs.com.hk/sme/default.page" target="_blank">星展集團</a>是亞洲最大的金融服務集團之一，業務遍及18個市場。總部設於新加坡並於當地上市，星展業務覆蓋亞洲三大增長主軸，即大中華、東南亞和南亞地區。星展資本充裕，所取得的AA-和Aa1級信貸評級位列全球最高級別之一。星展集團在亞洲提供包括零售銀行、中小企業銀行及大型企業銀行的全面金融服務。生於亞洲、長於亞洲，星展洞悉在亞洲這個充滿活力的市場經營業務的秘訣。</li><li><a href="https://www.xero.com/hk/" target="_blank">Xero</a>為小型企業提供會計雲端軟件服務，在全球擁有超過270萬名用戶。小型企業僱主及他們的業務顧問能透過Xero隨時隨地在任何裝置獲取實時財務數據。Xero提供一個整合超過1,000款應用程式及連接至超過300間銀行或金融機構的生態系統。在2020及2021年，Xero被納入彭博性別平等指數。在IDC MarketScape 2020年度的供應商評核中，Xero獲認可為全球軟體即服務商及中小企雲端會計與財務應用程式的領導者。</li>',
      meta_content: 'beNovelty彼樂科創特意舉辦網上研討會，聯同星展銀行(香港)以及Xero分享: 如何透過雲端會計軟件的轉數快收款功能，輕鬆管理企業收款會計流程。',
      canonical: 'https://hub.openapihub.com/community-updates/20210812-Cloud-Accounting-x-FinTech-How-to-Automate-Accounting-Processes-Using-FPS-Payment-Collection'
    },
    {
      url: 'events/aws_techconnect_series',
      image_url: 'events/webinars/banners/20210329_aws_techconnect_series.png',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Dive Deep on Running Modern Application with AWS Container Services',
      description: 'In this webinar, we discuss best practices in containerization, and identify some common customer scenarios during the adoption of AWS container services.',
      date: 'Mar 29, 2021 (Monday)',
      time: '1:00pm - 2:15pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      website: 'https://pages.awscloud.com/hk-tech-connect-container-services-webinar_20210329_registration.html?sc_ichannel=ha&sc_icampaign=field_containerwebinarhk2021&sc_isegment=en&sc_iplace=cta_ed&trk=ha_hkregionpage_en',
      button_text: 'Learn More',
      organiser: [
        {
          company: 'AWS',
          image_url: 'webinars/organisers/aws.png'
        }
      ],
      location: 'Online Event',
      language: '	Cantonese',
      fee: 'Free',
      about: [
        'Containers have emerged as an enabling technology for companies to drive rapid innovation and application development in a more cost effective way. Containers disrupt the traditional development cycle by driving developers to assume ownership of quality control for the apps and code developed. In this webinar, we discuss best practices in containerization, and identify some common customer scenarios during the adoption of AWS container services. beNovelty, a Hong Kong business providing one-stop API platform services, will share how to solve challenges and gain benefits from containers. You will learn',
        '1) What makes AWS the #1 place to run 80% of all containers in the cloud',
        '2) The different use cases AWS container services support',
        '3) Multiple container services AWS offers to help you deploy, manage, and scale containers',
        '4) How to perform the containers lifecycle management, such as logging, scaling, and platform upgrade',
        '5) How to integrate your container clusters with other AWS services',
        'The event is designed for technical decision makers, developers, DevOps, engineers, IT professionals, and anyone who is interested in learning more about container.'
      ],
      speaker: [
        {
          name: 'Ms. Tammy Tse',
          job_title: 'Senior Business Development Manager – Digital Native Business, Amazon Web Services'
        },
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/Founder, beNovelty'
        },
        {
          name: 'Mr. Benson Kwong',
          job_title: 'Solutions Architect, Amazon Web Services'
        },
        {
          name: 'Mr. Tsz Kin Yan',
          job_title: 'Solutions Architect, Amazon Web Services'
        }
      ],
      meta_content: 'AWS TechConnect Series – In this webinar, we discuss best practices in containerization, and identify some common customer scenarios during the adoption of AWS container services.',
      canonical: 'https://hub.openapihub.com/community-updates/20210329-Dive-Deep-on-Running-Modern-Application-with-AWS-Container-Services'
    },
    {
      url: 'events/20201112_benovelty_onedegree_fiberapi',
      image_url: 'events/webinars/banners/20201112_benovelty_onedegree_fiberapi.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '如何應對數碼化帶來的網絡風險？',
      description: '分享中小企如何提升網絡安全，以及如何以數碼保險有效管理網絡風險',
      date: 'Nov 12, 2020 (Thursday)',
      time: '2:00pm - 3:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended. The webinar materials are now available on-demand!',
      website: 'https://www.visiongo.hsbc.com.hk/zh-HK/event/benovelty_onedegree_fiberapi',
      button_text: 'Learn More',
      more_card: [
        {
          title: 'Watch This Webinar Now',
          description: 'If you missed this webinar, don\'t worry! The recording and presentation slide of the webinar are now available on-demand.',
          button_text: 'Watch Now',
          image_url: 'replay-video.svg',
          resource_url: 'https://hub.openapihub.com/member/community-updates/20201112-How-to-Handle-Cyber-security-Risk-Brought-by-Digitization/resources'
        }
      ],
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'OneDegree',
          image_url: 'webinars/organisers/onedegree.png'
        },
        {
          company: 'FiberAPI',
          image_url: 'webinars/organisers/fiberapi.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [
        '隨着疫情影響及政府資助，中小企今年更着重數碼轉型，紛紛添置軟件硬件和雲端工具，以應對員工在家工作及客戶在家消費的趨勢。',
        '然而，伴隨着數碼化的網絡風險亦不容忽視。由於資源有限，中小企更易成為黑客目標，員工在家工作時，公司或客戶的資料安全亦需留意。',
        '是次講座由beNovelty主辦，並邀請了香港虛擬保險公司OneDegree及FiberAPI，分享中小企如何提升網絡安全，以及如何以數碼保險有效管理網絡風險。'
      ],
      host: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        }
      ],
      speaker: [
        {
          name: 'Mr. Arthur Lee',
          job_title: 'Chief Executive, OneDegree Hong Kong Limited'
        },
        {
          name: 'Mr. Anthony Lai',
          job_title: 'Co-founder, FiberAPI Technologies Limited'
        }
      ],
      gallery: [
        {
          image_url: '20201112-1.jpg'
        },
        {
          image_url: '20201112-2.jpg'
        },
        {
          image_url: '20201112-3.jpg'
        },
        {
          image_url: '20201112-4.jpg'
        }
      ],
      meta_content: '是次講座由beNovelty主辦，並邀請了香港虛擬保險公司OneDegree及FiberAPI，分享中小企如何提升網絡安全，以及如何以數碼保險有效管理網絡風險。',
      canonical: 'https://hub.openapihub.com/community-updates/20201112-How-to-Handle-Cyber-security-Risk-Brought-by-Digitization'
    },
    {
      url: 'events/fintech_and_the_new_normal',
      image_url: 'events/webinars/banners/20201103_fintech_and_the_new_normal.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Hong Kong Fintech Week\u0027s Fintech Studio Masterclass - "Fintech and The New Normal"',
      description: 'FinTech Studio分享環節 ─ 全球處於新常態，從事金融科技的初創如何對策及捉緊機遇？',
      date: 'Nov 03, 2020 (Tuesday)',
      time: '4:00pm - 4:20pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'HKSTP',
          image_url: 'webinars/organisers/hkstp.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [
        '一年一度金融科技界盛事──香港金融科技周(HK Fintech Week) 2020將於11月2至6日於網上舉行。活動邀請來自全球超過350位嘉賓，透過活動中的5條直播頻道、多達100小時的討論環節與業界分享對行業趨勢的分析及展望。參加者更可透過虛擬展位 (Virtual booth) 與提供金融科技方案之企業互動交流，掌握金融科技發展最新資訊。作為推動金融科技發展的重要一員，我們亦積極參與是次活動，期望在以下環節與你見面。請踴躍參與，立即登記！',
        '1) Hong Kong Channel分享環節 ─ 面對金融科技的急速發展以及疫情影響，作為傳統銀行以及本港創科培育基地，可以如何相互配合，促成更多金融科技之發展?',
        '2) FinTech Studio分享環節 ─ 全球處於新常態，從事金融科技的初創如何對策及捉緊機遇?',
        '3) 了解最新金融科技創新方案 ─ 來自香港科學園20間科技企業向你展示與監管方案、區塊鏈、支付服務、API、人工智能、大數據，投資和財富管理等相關的金融科技方案'
      ],
      moderator: [
        {
          name: 'Mr. Bernard Ng',
          job_title: 'Senior Manager, HKSTP'
        }
      ],
      speaker: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        },
        {
          name: 'Mr. Wallace Chow',
          job_title: 'Fraud & Compliance Director, Regtics'
        },
        {
          name: 'Ms. Ivy Tse',
          job_title: 'Co-Founder & Director, FreightAmigo'
        }
      ],
      meta_content: '一年一度金融科技界盛事──香港金融科技周(HK Fintech Week) 2020將於11月2至6日於網上舉行。活動邀請來自全球超過350位嘉賓，透過活動中的5條直播頻道、多達100小時的討論環節與業界分享對行業趨勢的分析及展望。',
      canonical: 'https://hub.openapihub.com/community-updates/20201103-Hong-Kong-Fintech-Week-s-Fintech-Studio-Masterclass-Fintech-and-The-New-Normal'
    },
    {
      url: 'events/driving_innovations_in_open_banking',
      image_url: 'events/webinars/banners/20201102_driving_innovations_in_open_banking.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Fireside Chat: Driving Innovations in Open Banking',
      description: 'Whether you’re looking for fresh ideas, a developer to collaborate with, or just want to catch up on the open banking trend, this is a must-attend for banking practitioners.',
      date: 'Nov 02, 2020 (Monday)',
      time: '3:00pm - 3:45pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'HKSTP',
          image_url: 'webinars/organisers/hkstp.png'
        },
        {
          company: 'HSBC',
          image_url: 'webinars/organisers/hsbc.png'
        },
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      moderator: [
        {
          name: 'Mr. Peter Mok',
          job_title: 'Head of Strategic Partnership, Hong Kong Science and Technology Parks Corporation'
        }
      ],
      speaker: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        },
        {
          name: 'Ms Lareina Wang',
          job_title: 'Head of Smart Banking, Digital, Wealth and Personal Banking, HSBC Hong Kong'
        },
        {
          name: 'Ms Anita Li',
          job_title: 'Managing Director, Head of Digital & Strategic Business Change Commercial Banking, HSBC Hong Kong'
        },
        {
          name: 'Mr Eugene Hsia',
          job_title: 'Chief Corporate Development Officer, Hong Kong Science and Technology Parks Corporation'
        }
      ],
      gallery: [
        {
          image_url: '20201102-1.jpg'
        },
        {
          image_url: '20201102-2.jpg'
        },
        {
          image_url: '20201102-3.jpg'
        },
        {
          image_url: '20201102-4.jpg'
        }
      ],
      meta_content: 'Whether you’re looking for fresh ideas, a developer to collaborate with, or just want to catch up on the open banking trend, this is a must-attend for banking practitioners.',
      canonical: 'https://hub.openapihub.com/community-updates/20201102-Fireside-Chat-Driving-Innovations-in-Open-Banking'
    },
    {
      url: 'events/executive_seminar_technologies_in_finance',
      image_url: 'events/webinars/banners/20201022_executive_seminar_technologies_in_finance.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Executive Seminar: Technologies in Finance',
      description: 'Cyberport & CFTE are co-hosting the Technologies in Finance Executive Seminar as part of the “Cyberport Financial Practitioners #FinTech Training Programme”.',
      date: 'Oct 22, 2020 (Thursday)',
      time: '4:00pm - 7:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'Cyberport',
          image_url: 'webinars/organisers/cyberport.png'
        },
        {
          company: 'CFTE - Centre for Finance, Technology and Entrepreneurship',
          image_url: 'webinars/organisers/cfte.png'
        }
      ],
      location: 'Online Event',
      language: 'English',
      fee: 'Free',
      about: [
        'Cyberport & CFTE are co-hosting the Technologies in Finance Executive Seminar as part of the “Cyberport Financial Practitioners #FinTech Training Programme”, which aim to enrich Hong Kong’s FinTech talent pool.',
        'beNovelty\u0027s CEO / Founder Patrick CS Fan will join the panel discussion with Stephan Murer (ex Group CTO at UBS), Ritesh Jain (Former COO at HSBC) & Gaurav Mehra (Partner at EY) in the upcoming Executive Seminar to talk about the trends of OpenBanking, OpenAPI & CloudAdoption in financial institutions.'
      ],
      speaker: [
        {
          name: 'Mr. Stephan Murer',
          job_title: 'Senior Lecturer and Programme Director at CFTE, ex Group CTO, UBS'
        },
        {
          name: 'Mr. Ritesh Jain',
          job_title: 'Former COO at HSBC'
        },
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        }
      ],
      meta_content: '一年一度金融科技界盛事──香港金融科技周(HK Fintech Week) 2020將於11月2至6日於網上舉行。活動邀請來自全球超過350位嘉賓，透過活動中的5條直播頻道、多達100小時的討論環節與業界分享對行業趨勢的分析及展望。',
      canonical: 'https://hub.openapihub.com/community-updates/20201022-Executive-Seminar-Technologies-in-Finance'
    },
    {
      url: 'events/apidays-live-hongkong',
      image_url: 'thumbnails/apidays-live-hongkong.jpg',
      category_title: 'Conference',
      category: 'Conference',
      title: 'Apidays Live Hong Kong 2020',
      description: 'The Open API Economy - Finance-as-a-Service & API Ecosystems',
      date: 'Oct 8, 2020 (Thursday)',
      time: '9:00am - 6:00pm (HKT)',
      status: 'Event Ended'
    },
    {
      url: 'events/local_start-up_experience_sharing',
      image_url: 'events/webinars/banners/20200929_local_startup_experience_sharing.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Feasibility study on the quality of Hong Kong start-up development for supporting the sustainable business growth - Local start-up experience sharing',
      date: 'Sep 29, 2020 (Friday)',
      time: '2:00pm - 5:30pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'Hong Kong Society for Quality',
          image_url: 'webinars/organisers/hong_kong_society_for_quality.png'
        },
        {
          company: 'HKQAA',
          image_url: 'webinars/organisers/hkqaa.png'
        }
      ],
      location: 'HKSTP',
      language: 'English and Cantonese',
      fee: 'Free',
      about: [
        'Financial management, information security and quality assurance are the most common challenges being faced by local start-up. If you are dreaming big and want for your start-up business to the heights of success, join us as we bring you insights, experiences and trends from investors and corporates. Come check out for full series of events to exchange ideas, make connection and gain new business opportunities.'
      ],
      speaker: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        }
      ],
      meta_content: 'Feasibility study on the quality of Hong Kong start-up development for supporting the sustainable business growth - Local start-up experience sharing',
      canonical: 'https://hub.openapihub.com/community-updates/20200929-Feasibility-study-on-the-quality-of-Hong-Kong-start-up-development-for-supporting-the-sustainable-business-growth-Local-start-up-experience-sharing'
    },
    {
      url: 'events/future_of_law_legal_innovations_in_action_today',
      image_url: 'events/webinars/banners/20200907_future_of_law_legal_innovations_in_action_today.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Future of Law - Legal Innovations in Action Today',
      description: 'How APIs empowering automated legal services in the event of "Future of Law".',
      date: 'Sep 7, 2020 (Monday)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'HKSTP - Global Acceleration Academy (GAA)',
          image_url: 'webinars/organisers/hkstp.png'
        },
        {
          company: 'The Law Society of Hong Kong',
          image_url: 'webinars/organisers/the_law_society_of_hong_kong.png'
        }
      ],
      location: 'Online Event',
      language: 'English',
      fee: 'Free',
      about: [
        'How APIs empowering automated legal services in the event of "Future of Law"',
        'How ArchSD embarked on its \u0027Journey to Innovative Construction\u0027',
        'The setting of framework for coding for lawtech'
      ],
      speaker: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO/ Chief API Strategist , beNovelty Limited'
        },
        {
          name: 'Ms. Winnie Ho',
          job_title: 'Deputy Director of Architectural Services, Architectural Services Department (“ArchSD”)'
        },
        {
          name: 'Mr. Amirali Nasir',
          job_title: 'Vice-President and Chairman of InnoTech Committee'
        }
      ],
      meta_content: 'How APIs empowering automated legal services in the event of "Future of Law"',
      canonical: 'https://hub.openapihub.com/community-updates/20200907-Future-of-Law-Legal-Innovations-in-Action-Today'
    },
    {
      url: 'events/apiecobooster',
      image_url: 'thumbnails/apiecobooster.jpg',
      category_title: 'Challenge',
      category: 'Challenge',
      title: 'API EcoBooster',
      description: 'Embrace New Technology with Open APIs in SMART BANKING',
      date: 'Aug, 2020 - Oct, 2020',
      status: 'Event Ended'
    },
    {
      url: 'events/20200623_benovelty_taptab_boutir',
      image_url: 'events/webinars/banners/20200623_benovelty_taptab_boutir.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '零成本：5個你必須學懂的教育行業開展新收入方法',
      description: '分享教育行業可以如何在疫情中，透過政府資助，實踐數碼轉型，創造新收入',
      date: 'Jun 23, 2020 (Tuesday)',
      time: '12:00pm - 1:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      website: 'https://www.visiongo.hsbc.com.hk/zh-HK/event/taptab-boutir-webinar-0623',
      button_text: 'Learn More',
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'TapTab Education Technologies',
          image_url: 'webinars/organisers/taptab.png'
        },
        {
          company: 'Boutir',
          image_url: 'webinars/organisers/boutir.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [
        '疫症肆虐影響各行各業，當中對教育界影響甚為嚴重。疫情加速改變網上營商的生態，讓不少教育界的朋友尋求數碼轉型方法，以便靈活應對各種需要。',
        'beNovelty彼樂科創特意在滙豐機滙VisionGo平台舉辦網上研討會，聯同TapTab 點指教育及Boutir 掌鋪分享教育行業可以如何在疫情中，透過政府資助，實踐數碼轉型，創造新收入。'
      ],
      agenda: [
        '低成本高效益網上教學宣傳',
        '建構被動收入教育影片平台',
        '系統化管理教育中心',
        '教育網店營銷策略',
        '政府相關資助申請秘笈',
        'Q&A'
      ],
      host: [
        {
          name: 'Ms. Connie Au',
          job_title: 'Business Analyst, beNovelty'
        }
      ],
      speaker: [
        {
          name: 'Ms. Bibianna Wong',
          job_title: 'Head of Sales & Marketing, Boutir'
        },
        {
          name: 'Mr. Jacky Mo',
          job_title: 'Chief Product Officer, TapTab Education Technologies'
        }
      ],
      gallery: [
        {
          image_url: '20200623-1.jpg'
        },
        {
          image_url: '20200623-2.jpg'
        },
        {
          image_url: '20200623-3.jpg'
        },
        {
          image_url: '20200623-4.jpg'
        }
      ],
      meta_content: 'beNovelty彼樂科創特意在滙豐機滙VisionGo平台舉辦網上研討會，聯同TapTab 點指教育及Boutir 掌鋪分享教育行業可以如何在疫情中，透過政府資助，實踐數碼轉型，創造新收入。',
      canonical: 'https://hub.openapihub.com/community-updates/20200623-5-Zero-cost-Methods-You-Must-Know-to-Create-New-Revenue-Streams-in-Education-Industry'
    },
    {
      url: 'events/opportunity-for-education-industry-digital-transformation',
      image_url: 'events/webinars/banners/20200515_opportunity_for_education_industry_digital_transformation.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: '數碼轉型—教育行業的機會',
      description: '分享教育行業數碼轉型的機遇以及金融科技在教育行業的應用',
      date: 'May 15, 2020 (Friday)',
      time: '12:00pm - 1:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      website: 'https://www.visiongo.hsbc.com.hk/zh-HK/event/benovelty-opportunity-for-education-industry-digital-transformation',
      button_text: 'Learn More',
      organiser: [
        {
          company: 'beNovelty',
          image_url: 'webinars/organisers/benovelty.png'
        },
        {
          company: 'TapTab Education Technologies',
          image_url: 'webinars/organisers/taptab.png'
        },
        {
          company: 'FiberAPI',
          image_url: 'webinars/organisers/fiberapi.png'
        }
      ],
      location: 'Online Event',
      language: 'Cantonese',
      fee: 'Free',
      about: [
        '疫症肆虐影響各行各業，當中對教育界影響甚為嚴重，學生自新年假後開始停課，全面復課遙遙無期。相信不少教育界的朋友正尋求數碼轉型，以便靈活應對各種需要。',
        '有見及此，beNovelty彼樂科創特意在滙豐機滙VisionGo平台舉辦網上研討會，聯同TapTab 點指教育科技分享教育行業數碼轉型的機遇，我們亦分別邀請了HSBC和FiberAPI的講者分享金融科技在教育行業的應用，以及政府新一輪的防疫抗疫基金措施如何幫助業界轉危為機。'
      ],
      agenda: [
        '改變傳統教學觀念的契機',
        '教育界如何透過數碼轉型開拓商機',
        '教育界如何實現財務管理自動化以簡化公司營運',
        '轉數快在教育界的應用',
        '防疫抗疫基金措施如何幫助教育界轉危為機',
        'Q&A'
      ],
      host: [
        {
          name: 'Mr. Patrick Fan',
          job_title: 'CEO / Chief API Strategist, beNovelty'
        }
      ],
      speaker: [
        {
          name: 'Ms. Ivy Lam',
          job_title: 'Vice President, HSBC Innovation Partnership'
        },
        {
          name: 'Mr. Jacky Mo',
          job_title: 'Chief Product Officer, TapTab Education Technologies'
        },
        {
          name: 'Mr. Anthony Lai',
          job_title: 'General Manager , FiberAPI Technologies'
        }
      ],
      gallery: [
        {
          image_url: '20200515-1.jpg'
        },
        {
          image_url: '20200515-2.jpg'
        },
        {
          image_url: '20200515-3.jpg'
        },
        {
          image_url: '20200515-4.jpg'
        }
      ],
      meta_content: 'beNovelty彼樂科創特意在滙豐機滙VisionGo平台舉辦網上研討會，聯同TapTab 點指教育科技分享教育行業數碼轉型的機遇，我們亦分別邀請了HSBC和FiberAPI的講者分享金融科技在教育行業的應用，以及政府新一輪的防疫抗疫基金措施如何幫助業界轉危為機。',
      canonical: 'https://hub.openapihub.com/community-updates/20200515-Digital-Transformation-Opportunity-for-Education-Industry'
    },
    {
      url: 'events/api_trends_and_challenges',
      image_url: 'events/webinars/banners/20200514_api_trends_and_challenges.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Avoid Obstacles on Your API Journey - API Trends and Challenges',
      description: 'Join us in this trio of F5 Avoid Obstacles on Your API Journey Virtual Events.',
      date: 'May 14, 2020 (Thursday)',
      time: '2:00pm - 3:00pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      organiser: [
        {
          company: 'F5',
          image_url: 'webinars/organisers/f5.png'
        }
      ],
      location: 'Online Event',
      language: 'English',
      fee: 'Free',
      agenda: [
        'Global API Trends and Challenges',
        'How APIs are Comprised today',
        'Panel Discussion: API Utilization and Challenges in Hong Kong',
        'F5 Online Hackathon Overview'
      ],
      speaker: [
        {
          name: 'Mr. Patrick C.S. Fan',
          job_title: 'CEO/ Chief API Strategist, beNovelty'
        },
        {
          name: 'Ms. Sisi Yu',
          job_title: 'Head of Product, API, JETCO'
        },
        {
          name: 'Dr. Paul Sin',
          job_title: 'Leader of FinTech & Asia Pacific Blockchain Lab, Deloitte'
        },
        {
          name: 'Mr. Michael Mak',
          job_title: 'General Manager, Hong Kong and Macau, F5 Networks'
        },
        {
          name: 'Mr. Shahnawaz Backer',
          job_title: 'Principal Security Advisor, F5 Networks'
        }
      ],
      meta_content: 'Avoid Obstacles on Your API Journey - API Trends and Challenges',
      canonical: 'https://hub.openapihub.com/community-updates/20200514-Avoid-Obstacles-on-Your-API-Journey-API-Trends-and-Challenges'
    },
    {
      url: 'events/open_API_technology_entrepreneurial_journey_sharing',
      image_url: 'events/webinars/banners/20200317_open_API_technology_entrepreneurial_journey_sharing.jpg',
      category_title: 'Webinar',
      category: 'Webinar',
      title: 'Open API Technology & Entrepreneurial Journey Sharing',
      description: 'Come meet UST Alumni Patrick Fan and learn the trend and technology challenges of Open APIs',
      date: 'Mar 17, 2020 (Tuesday)',
      time: '6:00pm - 7:30pm (HKT)',
      status: 'Event Ended',
      status_description: 'This event has ended.',
      website: 'https://www.ec.ust.hk/event/1/online-open-api-technology-entrepreneurial-journey-sharing',
      button_text: 'Learn More',
      organiser: [
        {
          company: 'The Hong Kong University of Science and Technology - Entrepreneurship Center',
          image_url: 'webinars/organisers/ust_entrepreneurship_center.png'
        }
      ],
      location: 'Online Event',
      language: 'English',
      fee: 'Free',
      about: [
        'The API economy fundamentally changes the modern business value chain. An API (or Application Programming Interface) is now beyond a pure technical terminology and becoming a foundation strategy that every digital business should rethink.',
        'Come meet UST Alumni Patrick Fan and learn the trend and technology challenges of Open APIs, and how he started his entrepreneurial journey in converting 10 years Enterprise Consulting experience to 5 years of successful Entrepreneurship!'
      ],
      agenda: [
        'The regional trend of open API economy (Open Banking, Open Insurance, Open Tourism etc.)',
        'The common challenges of open API ecosystem',
        'What is happening in Hong Kong and why it matters to you'
      ],
      speaker: [
        {
          name: 'Mr. Patrick C.S. Fan',
          job_title: 'CEO/ Chief API Strategist, beNovelty'
        }
      ],
      meta_content: 'The API economy fundamentally changes the modern business value chain. An API (or Application Programming Interface) is now beyond a pure technical terminology and becoming a foundation strategy that every digital business should rethink.',
      canonical: 'https://hub.openapihub.com/community-updates/20200317-Open-API-Technology-Entrepreneurial-Journey-Sharing'
    }
  ]
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}
