const state = {
  partner_list: [
    // {
    //   comapny: 'AWS',
    //   image_url: 'aws.jpg'
    // },
    // {
    //   comapny: 'Kong Inc.',
    //   image_url: 'kong.jpg'
    // },
    {
      comapny: 'jebsen',
      image_url: 'jebsen.png'
    },
    {
      comapny: 'jrg',
      image_url: 'jrg.png'
    },
    {
      comapny: 'cmhk',
      image_url: 'cmhk.png'
    },
    // {
    //   comapny: 'ASWatson',
    //   image_url: 'aswatson.png'
    // },
    {
      comapny: 'Belun',
      image_url: 'belun-square.png'
    },
    // {
    //   comapny: 'Telkie',
    //   image_url: 'telkie.jpg'
    // },
    // {
    //   comapny: 'Taptab',
    //   image_url: 'taptab.jpg'
    // },
    // {
    //   comapny: 'FiberAPI',
    //   image_url: 'fiberapi.jpg'
    // }
    {
      comapny: 'QFPAY',
      image_url: 'qfpay.png'
    }
  ],
  api_partner_list: [
    // {
    //   comapny: 'AWS',
    //   image_url: 'aws.jpg'
    // },
    // {
    //   comapny: 'Kong Inc.',
    //   image_url: 'kong.jpg'
    // },
    {
      comapny: 'jebsen',
      image_url: 'jebsen.png'
    },
    {
      comapny: 'jrg',
      image_url: 'jrg.png'
    },
    {
      comapny: 'cmhk',
      image_url: 'cmhk.png'
    },
    // {
    //   comapny: 'ASWatson',
    //   image_url: 'aswatson.png'
    // },
    {
      comapny: 'Belun',
      image_url: 'belun-square.png'
    },
    // {
    //   comapny: 'Telkie',
    //   image_url: 'telkie.jpg'
    // },
    // {
    //   comapny: 'Taptab',
    //   image_url: 'taptab.jpg'
    // },
    // {
    //   comapny: 'FiberAPI',
    //   image_url: 'fiberapi.jpg'
    // }
    {
      comapny: 'QFPAY',
      image_url: 'qfpay.png'
    }
  ],
  testimonial_list: [
    {
      comapny: 'HSBC Hong Kong',
      image_url: 'empty-hsbc.png',
      industry: 'Banking',
      content: '“ With our extensive client network and industry-leading position, we are pleased to find synergy with beNovelty\’s technology capability and together help companies leverage technology and enhance the public\’s everyday experience. ”',
      name: 'Terence Chiu',
      job_title: 'Head of Commercial Banking, Hong Kong, HSBC'
    },
    {
      comapny: 'Jebsen & Co. Ltd',
      image_url: 'jebsen.png',
      industry: 'Retail',
      content: '“ For newbie of API Gateway & microservices, beNovelty can help to quickly deliver the platform as well as share the critical knowledges in a short time. ”',
      name: 'Ming NG',
      job_title: 'Application Analyst, Group Information Technology, Jebsen & Co. Ltd'
    },
    {
      comapny: 'Belun Tech Company Limited',
      image_url: 'belun.png',
      industry: 'Healthcare',
      content: '“ beNovelty provides us professional end-to-end Cloud & Mobile solutions for our healthcare wearable devices. The solution adopts DevOps automation and multi-cloud architecture for our strategic regional expansion in a secured way. In beNovelty we trusted! ”',
      name: 'Lydia Leung',
      job_title: 'CEO, Belun Technology Company Limited'
    },
    {
      comapny: 'QFPay Haojin FinTech Limited',
      image_url: 'qfpay.svg',
      industry: 'Digital Payment',
      content: '" beNovelty’s agile coaches make our team more efficient, transparent and cohesive to catch our fast growing payment businesses in Hong Kong. With beNovelty solid expertise, our team is able to setup a solid foundation, tooling and practices in agile product development across Hong Kong, China & international Teams. "',
      name: 'Kevin Kam',
      job_title: 'Chief Digital Officer, QFPay Haojin FinTech Limited'
    },
    {
      comapny: 'HKSTP',
      image_url: 'hkstp.png',
      industry: 'Technology Ecosystem',
      content: '" With the Enterprise and Startup experiences in API development and understanding of pain points in the industry. We would like to invite beNovelty Limited as the strategy partner, supporting the training modules of the programme. "',
      name: 'Gallen Leung',
      job_title: 'Senior Manager, HKSTP'
    },
    {
      comapny: 'Spread-it Limited',
      image_url: 'spread-it.png',
      industry: 'Digital Marketing',
      content: '“ beNovelty provides insightful technical advice for our regional expansion and we are able to test the overseas market with the best balance of go-to-market time and budget. The talented team has also assists us to setup a solid product development methodology to make our business & technical team more transparent and cohesive. ”',
      name: 'Timothy Ng',
      job_title: 'Co-founder, Spread-it Limited'
    },
    {
      comapny: 'Sunstar Creation Limited',
      image_url: 'sunstar.png',
      industry: 'Education',
      content: '“ beNovelty helps transform our human-intense operation into a smart and automated business so that we can focus on our client. They saved us from the migration of legacy solution to AWS in a flexible and agile way. ”',
      name: 'Ricky Kwong',
      job_title: 'Managing Director, Sunstar Creation Limited'
    },
    {
      comapny: 'Telkie Limited',
      image_url: 'telkie.png',
      industry: 'TravelTech',
      content: '“ beNovelty provides an excellent messaging & chatbot solution for our hotel startup business. The AWS cloud solution also helps secure our regional expansion in a no brainer ”',
      name: 'Alex Cheng',
      job_title: 'CEO, Telkie Limited'
    },
    {
      comapny: 'FiberAPI Technologies Limited',
      image_url: 'fiberapi.png',
      industry: 'FinTech',
      content: '" beNovelty\'s OpenAPIHub provides a solid technical API Capability for us so that we can focus on the business innovation with our financial API partners. "',
      name: 'Anthony Lai',
      job_title: 'General Manager, FiberAPI Technologies Limited'
    }
  ]
}

const mutations = {
//   SET_PROFILE: (state, profile) => {
//     state.userProfile = profile
//   }
}

export default {
  namespaced: true,
  state,
  mutations
}

