<template>
  <div id="app">
    <Header id="header" />
    <router-view id="main" />
    <GoToTopButton />
    <Footer v-show="!showDummy" id="footer" />
  </div>
</template>

<script>
const $ = require('jquery')
import Header from '@/components/header'
import Footer from '@/components/footer'
import GoToTopButton from '@/components/go-to-top-button'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoToTopButton
  },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters(['showDummy'])
  },
  watch: {
    $route() {
      this.isLoaded = false
    }
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    showFooter() {
      this.isLoaded = true
    }
  }
}
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap'); */
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('//cdn-images.mailchimp.com/embedcode/classic-071822.css');

.none {
  display: none;
}
</style>
