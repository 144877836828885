const state = {
  intern_feedback_list: [
    {
      content: 'During my internship as a System Engineer, I had the opportunity to manage ISO documents, deploy automated scheduled tasks, and write test scripts for the FabriX website. This experience introduced me to new tools like Jenkins and Cypress. I learned the importance of documentation in maintaining quality standards, the efficiency and scalability benefits of automation using Jenkins, and the significance of thorough testing with Cypress. Additionally, I discovered the value of embracing new technologies, fostering collaboration and communication within a team, and the power of test automation in delivering a seamless user experience. This internship has expanded my technical skills, ignited my passion for continuous improvement, and will undoubtedly shape my future endeavors in systems engineering and automation.',
      name: 'Computer Science',
      school: 'HKUST'
    },
    {
      content: 'During my internship at beNovelty, I gained invaluable experience in web development. Over the course of two months, I embarked on numerous hands-on projects that greatly expanded my knowledge in this field. I acquired proficiency in utilizing PostgreSQL, successfully created multiple Express endpoints, and effectively tackled challenges by leveraging various AWS services. This journey has been truly rewarding, thanks to the guidance of supportive mentors and the creation of cherished memories. Collaborating with seasoned team members has taught me not only how to code, but also how to think critically and continuously enhance my learning abilities. If I were to summarize my newfound wisdom in a single sentence, it would be "focus on understanding the \'why\' rather than solely on the \'how\'.',
      name: 'Dual Degree Program in Technology & Management',
      school: 'HKUST'
    },
    {
      content: 'If you work here, you will definitely want to work in a small company in future with such a good working environment. Also, you can get much more opportunities than joining internship in a big company.',
      name: 'Computer Science',
      school: 'HKUST'
    },
    {
      content: 'Great place, great people, great fun! Three months was not as long as I thought. Though sometimes the work can be frustrating, that\'s a great opportunity to get a taste of working as a software developer, let alone the joy of conquering a predicament. Now I will say I\'m much more prepared and ready for more challenges.',
      name: 'Computer Science',
      school: 'CUHK'
    },
    {
      content: 'During these 3 months, I tried many new stuffs which are challenging for me. Firstly, I learn the importance of priorities working items and to-do list, as it helps me to manage my time, remind what I need to do and ensures everything on the right track. Secondly, I learn the importance of communication and coordination between peers. During every week meeting and discussion, I learn how to communicate effectively.',
      name: 'ISMT (Marketing)',
      school: 'HKUST'
    },
    {
      content: 'It was an interesting internship experience where I could learn through working on the projects and friendly colleagues guiding me to achieve more in this experience.',
      name: 'Computer Science',
      school: 'HKUST'
    },
    {
      content: 'My internship was a memorable experience where I learned a bunch of amazing skills and apply it in a real project, all while being supported by an awesome team that never let me feel left behind.',
      name: 'Computer Science',
      school: 'HKU'
    },
    {
      content: 'It\'s a great chance to be here working, learning with different experts. This internship provides me a good opportunity to apply theoretical knowledge to real-world scenarios, helping me develop practical frontend skills. More importantly, I can learn about workplace etiquette, communication skills, time management, and teamwork, all of which are crucial for professional growth. Finally, this internship experience make me know more about web development industry and clear my concerns about my future.',
      name: 'Computer Science',
      school: 'CUHK'
    },
    {
      content: 'Really the four values stated in the company website (beDedicated, knowledgable, trusted, innovative)',
      name: 'Dual Degree Program in Technology & Management',
      school: 'HKUST'
    }
  ]
}

const mutations = {
//   SET_PROFILE: (state, profile) => {
//     state.userProfile = profile
//   }
}

export default {
  namespaced: true,
  state,
  mutations
}

