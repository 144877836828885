const state = {
  apidays_2022_recap_list: [
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Powering Modern Business Banking Through APIs',
      speaker: 'richardlord',
      speaker_img: '2022/speakers/richard-pro.jpg',
      speaker_name: 'Richard Lord',
      speaker_position: 'MD, Regional CIO Wholesale - ASP at HSBC',
      video_url: 'https://www.youtube.com/watch?v=V8MNxCdDkfA&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Capturing business value with API',
      speaker: 'trevorcheung',
      speaker_img: '2022/speakers/trevor-pro.jpg',
      speaker_name: 'Trevor Cheung',
      speaker_position: 'General Manager, Chief Digital Officer of Corporate Banking at Bank of China (Hong Kong)',
      video_url: 'https://www.youtube.com/watch?v=dQx7d7FwniE&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Open Banking Ecosystem – Development, Opportunities & Challenges',
      speaker: 'jonathancheung',
      speaker_img: '2022/speakers/jonathan-pro.jpg',
      speaker_name: 'Jonathan Cheung',
      speaker_position: 'Director, Open Banking & API, Digital Banking at Standard Chartered Bank (Hong Kong) Limited',
      video_url: 'https://www.youtube.com/watch?v=PGk4WOgVNaQ&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Digital Ecosystem enabled by API & Big data',
      speaker: 'garyho',
      speaker_img: '2022/speakers/gary-pro.jpg',
      speaker_name: 'Gary Ho',
      speaker_position: 'Chief Technology Officer of AXA HK and Macau',
      video_url: 'https://www.youtube.com/watch?v=GdC04vWxT5g&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Banking & Open APIs',
      speaker: 'garylam',
      speaker_img: '2022/speakers/garyl-pro.jpg',
      speaker_name: 'Gary Lam',
      speaker_position: 'Chief Technology Officer of Livi Bank',
      video_url: 'https://www.youtube.com/watch?v=2L2u7k9PKBA&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'The Open API Ecosystem',
      speaker: 'martinliu',
      speaker_img: '2022/speakers/martin-pro.jpg',
      speaker_name: 'Martin Liu',
      speaker_position: 'Assistant Director, Platform Development, STP Platform at Hong Kong Science and Technology Parks Corporation',
      video_url: 'https://www.youtube.com/watch?v=eZy8T4f7-BM&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Critical Role of Data in Platform Economy',
      speaker: 'toacharm',
      speaker_img: '2022/speakers/toa-pro.jpg',
      speaker_name: 'Dr. Toa Charm',
      speaker_position: 'Associate Professor of Practice in Innovation and Technology at CUHK Business School',
      video_url: 'https://www.youtube.com/watch?v=3Kf8mDjju78&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'The API Trends and Beyond',
      speaker: 'jimmytsang',
      speaker_img: '2022/speakers/jimmy-pro.jpg',
      speaker_name: 'Jimmy Tsang',
      speaker_position: 'EASG Committee at Hong Kong Computer Society',
      video_url: 'https://www.youtube.com/watch?v=Bq59rf2qiCU&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Innovation and Impact - Adopting APIs for enterprise transformation',
      speaker: 'nelsonchan',
      speaker_img: '2022/speakers/nelson-pro.jpg',
      speaker_name: 'Nelson Chan',
      speaker_position: 'Head of Financial Services Business Development, Hong Kong & Taiwan at AWS',
      video_url: 'https://www.youtube.com/watch?v=4p_cxhYnhQ0&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Serving Data as API',
      speaker: 'easonlai',
      speaker_img: '2022/speakers/eason-pro.jpg',
      speaker_name: 'Eason Lai',
      speaker_position: 'Global Technology Strategist at Microsoft',
      video_url: 'https://www.youtube.com/watch?v=Xv-BGgwbInY&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Create Value and Insight Through Privacy-preserving Data Collaboration with Federated Learning',
      speaker: 'clivechan',
      speaker_img: '2022/speakers/clive-pro.jpg',
      speaker_name: 'Clive Chan',
      speaker_position: 'Principal Solution Architect at Openhive',
      video_url: 'https://www.youtube.com/watch?v=TpLTyyJmBc8&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Data collaboration with Federated Learning',
      speaker: 'issacwong',
      speaker_img: '2022/speakers/issac-pro.jpg',
      speaker_name: 'Issac Wong',
      speaker_position: 'Principal AI Solution Architect at Openhive',
      video_url: 'https://www.youtube.com/watch?v=BOuC_R6umqM&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'API Modernization with GraphQL',
      speaker: 'wilsonwong',
      speaker_img: '2022/speakers/wilson-pro.jpg',
      speaker_name: 'Wilson Wong',
      speaker_position: 'Senior Solutions Architect at AWS',
      video_url: 'https://www.youtube.com/watch?v=NwRGYK6fn2E&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Attack API Architecture',
      speaker: 'alvintam',
      speaker_img: '2022/speakers/alvin-pro.jpg',
      speaker_name: 'Alvin Tam',
      speaker_position: 'EASG Committee at Hong Kong Computer Society',
      video_url: 'https://www.youtube.com/watch?v=2xIaPLBlfRQ&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'APIs + Data integration capabilities as tools for building faster and adaptive digital solutions',
      speaker: 'rayyip',
      speaker_img: '2022/speakers/ray-pro.jpg',
      speaker_name: 'Ray Yip',
      speaker_position: 'EASG Committee at Hong Kong Computer Society',
      video_url: 'https://www.youtube.com/watch?v=1niZAVRvgiQ&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Insights As a Service with APIs',
      speaker: 'joeywong',
      speaker_img: '2022/speakers/joey-pro.jpg',
      speaker_name: 'Joey Wong',
      speaker_position: 'Customer Engineer at Google',
      video_url: 'https://www.youtube.com/watch?v=lYQXDfhVpNo&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Open Banking is an "How" and Embedded Finance is an "Why"',
      speaker: 'patrickfan',
      speaker_img: '2022/speakers/patrick-pro.jpg',
      speaker_name: 'Patrick C.S. Fan',
      speaker_position: 'CEO & Founder of beNovelty Limited',
      video_url: 'https://www.youtube.com/watch?v=3U2dhu4DfbE&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Add your first asynchronous API to your app in 30 minutes',
      speaker: 'dalelane',
      speaker_img: '2022/speakers/dale-pro.jpg',
      speaker_name: 'Dale Lane',
      speaker_position: 'Senior Technology Engineer at IBM',
      video_url: 'https://www.youtube.com/watch?v=3wNGoe0ytsg&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Evolving your APIs, a step-by-step approach',
      speaker: 'nicolasfrankel',
      speaker_img: '2022/speakers/nicolas-pro.jpg',
      speaker_name: 'Nicolas Fränkel',
      speaker_position: 'Head of Developer Advocacy at Apache APISIX',
      video_url: 'https://www.youtube.com/watch?v=DPyfaFXmsV0&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'APIs for Scaling Enterprises: Setting and Applying API Standards',
      speaker: 'jeremyglassenberg',
      speaker_img: '2022/speakers/jeremy-pro.jpg',
      speaker_name: 'Jeremy Glassenberg',
      speaker_position: 'Product Management Lead, APIs at Docusign',
      video_url: 'https://www.youtube.com/watch?v=GeEYmixSPDA&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Digital Transformation - Potential Pitfalls, Protection and PET\'s',
      speaker: 'sonalrattan',
      speaker_img: '2022/speakers/sonal-pro.jpg',
      speaker_name: 'Sonal Rattan',
      speaker_position: 'Chief Technology Officer at eXate',
      video_url: 'https://www.youtube.com/watch?v=N4dzH8E8m90&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Event API Products: Maximizing the Value of Your Event-Driven Architecture',
      speaker: 'girivenkatesan',
      speaker_img: '2022/speakers/giri-pro.jpg',
      speaker_name: 'Giri Venkatesan',
      speaker_position: 'Developer Advocate at Solace',
      video_url: 'https://www.youtube.com/watch?v=owo9_DDh-Zs&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Why is API Gateway essential to business?',
      speaker: 'zhiyuanju',
      speaker_img: '2022/speakers/zhiyuan-pro.jpg',
      speaker_name: 'Zhiyuan Ju',
      speaker_position: 'Head of Global at API7.ai',
      video_url: 'https://www.youtube.com/watch?v=5veKk4ZrJGY&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Let\'s build an AI-powered API in 30 Minutes',
      speaker: 'ulihitzel',
      speaker_img: '2022/speakers/uli-pro.jpg',
      speaker_name: 'Uli Hitzel',
      speaker_position: 'Founder of Electric Minds',
      video_url: 'https://www.youtube.com/watch?v=aqjgWaIyHUU&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Developers Documentation: your secret weapon',
      speaker: 'patrickfan',
      speaker_img: '2022/speakers/patrick-pro.jpg',
      speaker_name: 'Patrick C.S. Fan',
      speaker_position: 'CEO & Founder of beNovelty Limited',
      video_url: 'https://www.youtube.com/watch?v=wLXkzX_jDCg&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Creating Great Developer Experiences with Metrics and Automation',
      speaker: 'matttanner',
      speaker_img: '2022/speakers/matt-pro.jpg',
      speaker_name: 'Matt Tanner',
      speaker_position: 'Head of Developer Relations at Moesif',
      video_url: 'https://www.youtube.com/watch?v=RBWH-L5-StQ&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Offensive API Penetration Testing',
      speaker: 'sunnysingh',
      speaker_img: '2022/speakers/sunny-pro.jpg',
      speaker_name: 'Sunny Singh',
      speaker_position: 'Lead Security Engineer at Calibo',
      video_url: 'https://www.youtube.com/watch?v=7fjsSFHJwZo&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'The truth about anomaly detection in API security',
      speaker: 'robdickinson',
      speaker_img: '2022/speakers/rob-pro.jpg',
      speaker_name: 'Rob Dickinson',
      speaker_position: 'Co-founder and CTO of Resurface Labs Inc.',
      video_url: 'https://www.youtube.com/watch?v=upqLHO4C_o4&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Spinning Your Drones with Cadence Workflows and Apache Kafka',
      speaker: 'paulbrebner',
      speaker_img: '2022/speakers/paul-pro.jpg',
      speaker_name: 'Paul Brebner',
      speaker_position: 'Open Source Technology Evangelist at Instaclustr by NetApp',
      video_url: 'https://www.youtube.com/watch?v=modA6g5cBlg&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'The State of Observability',
      speaker: 'mottibechhofer',
      speaker_img: '2022/speakers/motti-pro.jpg',
      speaker_name: 'Motti Bechhofer',
      speaker_position: 'Senior Software Engineer at Aspecto',
      video_url: 'https://www.youtube.com/watch?v=lCaSyLD9Yf4&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Leveraging Event Streaming to Super-Charge your Business Solutions',
      speaker: 'marygrygleski',
      speaker_img: '2022/speakers/mary-pro.jpg',
      speaker_name: 'Mary Grygleski',
      speaker_position: 'Senior Streaming Developer Advocate at DataStax',
      video_url: 'https://www.youtube.com/watch?v=gZhtok04QtI&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Best practices when monetizing API',
      speaker: 'derricgilling',
      speaker_img: '2022/speakers/derric-pro.jpg',
      speaker_name: 'Derric Gilling',
      speaker_position: 'CEO at Moesif',
      video_url: 'https://www.youtube.com/watch?v=s9Ls1QY43U8&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'How to Build and Leverage APIs Sustainably to Reduce Digital Waste',
      speaker: 'braddrysdale',
      speaker_img: '2022/speakers/brad-pro.jpg',
      speaker_name: 'Brad Drysdale',
      speaker_position: 'Field CTO for APJ at Kong',
      video_url: 'https://www.youtube.com/watch?v=nvYD9aSIIDk&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'The API Landscape',
      speaker: 'mehdimedjaoui',
      speaker_img: '2022/speakers/mehdi-pro.jpg',
      speaker_name: 'Mehdi Medjaoui',
      speaker_position: 'Founder of apidays conference',
      video_url: 'https://www.youtube.com/watch?v=9bm5OlyIHxg&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'The Story of OpenAI GPT3 - Improve Observability with Tracing',
      speaker: 'steveng',
      speaker_img: '2022/speakers/steve-pro.jpg',
      speaker_name: 'Steve Ng',
      speaker_position: 'Developer Relations Lead APJ at New Relic',
      video_url: 'https://www.youtube.com/watch?v=7pIGdVVoYPA&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Open Source Instrumentation - The Rise of OpenTelemetry',
      speaker: 'steveng',
      speaker_img: '2022/speakers/steve-pro.jpg',
      speaker_name: 'Steve Ng',
      speaker_position: 'Developer Relations Lead APJ at New Relic',
      video_url: 'https://www.youtube.com/watch?v=gGxWl2xGXWM&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Matchmaking ESG & Sustainability with Open Banking APIs',
      speaker: 'enriquegoizueta',
      speaker_img: '2022/speakers/enrique-pro.jpg',
      speaker_name: 'Enrique Goizueta',
      speaker_position: 'Head of Sales, APAC at Meniga',
      video_url: 'https://www.youtube.com/watch?v=glBzrozUqUk&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 1',
      category: 'Day1Stage1',
      badge_css: 'badge-soft-primary',
      title: 'Connecting to Web3 - DeFi and beyond',
      speaker: 'giovannilesna',
      speaker_img: '2022/speakers/giovanni-pro.jpg',
      speaker_name: 'Giovanni Lesna',
      speaker_position: 'Head of Enterprise & Partnerships at API3',
      video_url: 'https://www.youtube.com/watch?v=8IoSwoo1F9Y&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Transforming the Bank: API-driven banking beyond Open APIs',
      speaker: 'simonredfern',
      speaker_img: '2022/speakers/simon-pro.jpg',
      speaker_name: 'Simon Redfern',
      speaker_position: 'CEO and Founder of TESOBE / The Open Bank Project',
      video_url: 'https://www.youtube.com/watch?v=muOaFsqyV4I&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Add your first asynchronous API to your app in 30 minutes',
      speaker: 'stevenpatrick',
      speaker_img: '2022/speakers/steven-pro.jpg',
      speaker_name: 'Steven Patrick',
      speaker_position: 'Technology Engineer at IBM',
      video_url: 'https://www.youtube.com/watch?v=3wNGoe0ytsg&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 1',
      category: 'Day2Stage1',
      badge_css: 'badge-soft-success',
      title: 'Cloud Databases, ELT, and APIs - A Perfect Storm?',
      speaker: 'bobbrauer',
      speaker_img: '2022/speakers/bob-pro.jpg',
      speaker_name: 'Bob Brauer',
      speaker_position: 'CEO & Founder of Interzoid',
      video_url: 'https://www.youtube.com/watch?v=3KY-lm063BY&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 1 Stage 2',
      category: 'Day1Stage2',
      badge_css: 'badge-soft-warning',
      title: 'Data Mesh and Data API',
      speaker: 'kennethcheung',
      speaker_img: '2022/speakers/kenneth-pro.jpg',
      speaker_name: 'Kenneth Cheung',
      speaker_position: 'Staff Solutions Engineer at Confluent',
      video_url: 'https://www.youtube.com/watch?v=auD5UoGlyuA&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Stream Processing with KSQLDB and REST API Queries',
      speaker: 'sanvysabapathee',
      speaker_img: '2022/speakers/sanvy-pro.jpg',
      speaker_name: 'Sanvy Sabapathee',
      speaker_position: 'Senior Solutions Engineer at Confluent',
      video_url: 'https://www.youtube.com/watch?v=ivMFM9ASUuE&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Workshop & Roundtable',
      category: 'WorkshopRoundtable',
      badge_css: 'badge-soft-info',
      title: 'Optimize Your API with Event-Driven Microservice Architecture',
      speaker: 'jeffreylam',
      speaker_img: '2022/speakers/jeffrey-pro.jpg',
      speaker_name: 'Jeffrey Lam',
      speaker_position: 'Staff Solutions Engineer at Confluent',
      video_url: 'https://www.youtube.com/watch?v=zvbfQN-B4po&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    },
    {
      category_title: 'Day 2 Stage 2',
      category: 'Day2Stage2',
      badge_css: 'badge-soft-yellow',
      title: 'Deploying and building SDKs as a service',
      speaker: 'developerstevecoochin',
      speaker_img: '2022/speakers/developersteve-pro.jpg',
      speaker_name: 'DeveloperSteve Coochin',
      speaker_position: 'Senior Developer Advocate at Lumigo',
      video_url: 'https://www.youtube.com/watch?v=JruxnSOoQRc&list=PLmEaqnTJ40Oo6pAJen-m8rnmb8O4MwTlK'
    }
  ]
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

