const state = {
  apidays_2020_recap_list: [
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'State of the API Economy',
      speaker: 'alanglickenhouse',
      speaker_img: 'speakers/alan-pro.jpg',
      speaker_name: 'Alan Glickenhouse',
      speaker_position: 'Digital Transformation and API Business Strategist at IBM',
      video_url: 'https://www.youtube.com/watch?v=sBcA7FZKeUE&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-state-of-the-api-economy-by-alan-glickenhouse'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Beyond Open Banking - Open Innovation & Open Business',
      speaker: 'alexchan',
      speaker_img: 'speakers/alex-pro.jpg',
      speaker_name: 'Alex Chan',
      speaker_position: 'CEO of FORMS HK',
      video_url: 'https://www.youtube.com/watch?v=qqyceDan17Q&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-beyond-open-banking-open-innovation-open-business-by-alex-chan'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Why You Need a DevRel Team for Your API',
      speaker: 'annatsolakou',
      speaker_img: 'speakers/anna-pro.jpg',
      speaker_name: 'Anna Tsolakou',
      speaker_position: 'Developer Advocate at Amadeus',
      video_url: 'https://www.youtube.com/watch?v=2cK6Rf-BC1k&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-why-you-need-a-devrel-team-for-your-api-by-anna-tsolakou'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Achieving True Agility Through Programmability',
      speaker: 'asankaabeysinghe',
      speaker_img: 'speakers/asanka-pro.jpg',
      speaker_name: 'Asanka Abeysinghe',
      speaker_position: 'Chief Technology Evangelist at WSO2, Inc',
      video_url: 'https://www.youtube.com/watch?v=NBgd-wGDe08&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Let\'s Get Started Development of API Client Library',
      speaker: 'atsushinakatsugawa',
      speaker_img: 'speakers/atsushi-pro.jpg',
      speaker_name: 'Atsushi Nakatsugawa',
      speaker_position: 'CEO of MOONGIFT',
      video_url: 'https://www.youtube.com/watch?v=hsyR5EocPBU&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-lets-get-started-development-of-api-client-library-by-atsushi-nakatsugawa'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Digital Banking Survey in HK and How it Relates to Open Banking',
      speaker: 'barrychan',
      speaker_img: 'speakers/barry-pro.jpg',
      speaker_name: 'Barry Chan',
      speaker_position: 'Partner, Financial Services Sector of IBM',
      video_url: 'https://www.youtube.com/watch?v=DEGJxZq1ZHg&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-digital-banking-survey-in-hk-and-how-it-relates-to-open-banking-by-barry-chan'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Authorised is Not a Yes/No Question',
      speaker: 'ben dechrai',
      speaker_img: 'speakers/ben-pro.jpg',
      speaker_name: 'Ben Dechrai',
      speaker_position: 'Developer Advocate at Auth0',
      video_url: 'https://www.youtube.com/watch?v=FVMts6NFgSo&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Health Oriented API Design for the Public Conversation',
      speaker: 'danielebernardi',
      speaker_img: 'speakers/daniele-pro.jpg',
      speaker_name: 'Daniele Bernardi',
      speaker_position: 'Staff Developer Relations at Twitter',
      video_url: 'https://www.youtube.com/watch?v=YhuunV5o3Y4&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-health-oriented-api-design-for-the-public-conversation-by-daniele-bernardi'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'API Abuse - Comprehension and Prevention',
      speaker: 'davidstewart',
      speaker_img: 'speakers/david-pro.jpg',
      speaker_name: 'David Stewart',
      speaker_position: 'CEO of Approov',
      video_url: 'https://www.youtube.com/watch?v=CRBH1ag94PY&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-api-abuse-comprehension-and-prevention-by-david-stewart'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Solving Challenges in API Testing to Achieve More Agile Development',
      speaker: 'developerstevecoochin',
      speaker_img: 'speakers/developersteve-pro.jpg',
      speaker_name: 'DeveloperSteve Coochin',
      speaker_position: 'Developer Advocate at IBM Australia',
      video_url: 'https://www.youtube.com/watch?v=PnnbqGBNnmM&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Orchestrating Workflow for Microservices Integration',
      speaker: 'donnieprakoso',
      speaker_img: 'speakers/donnie-pro.jpg',
      speaker_name: 'Donnie Prakoso',
      speaker_position: 'Senior Developer Advocate at Amazon Web Services (AWS)',
      video_url: 'https://www.youtube.com/watch?v=9X8iewXGct8&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Workshop Track',
      category: 'WorkshopTrack',
      badge_css: 'badge-soft-warning',
      title: 'Build your APIs and DevOps Hybrid Skills',
      speaker: 'forestjing',
      speaker_img: 'speakers/forest-pro.jpg',
      speaker_name: 'Forest Jing',
      speaker_position: 'DAOPS Foundation China Director at DevOps',
      video_url: 'https://www.youtube.com/watch?v=RY3pDoPhJQI&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'AsyncAPI & The Future of API Specs',
      speaker: 'franmendez',
      speaker_img: 'speakers/fran-pro.jpg',
      speaker_name: 'Fran Mendez',
      speaker_position: 'Founder of AsyncAPI Initiative',
      video_url: 'https://www.youtube.com/watch?v=JMwDikzGF9Q&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-asyncapi-the-future-of-api-specs-by-fran-mendez'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Fast Track the Open Banking Ecosystem with Platform Business Model',
      speaker: 'garrysien',
      speaker_img: 'speakers/garry-pro.jpg',
      speaker_name: 'Garry Sien',
      speaker_position: 'Principal Advisory Consultant at Alibaba Cloud International',
      video_url: 'https://www.youtube.com/watch?v=vZH1bod7ym8&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-fast-track-the-open-banking-ecosystem-with-platform-business-model-by-garry-sien'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Going API First in Financial Services within Greater China',
      speaker: 'gregau-yeung',
      speaker_img: 'speakers/greg-pro.jpg',
      speaker_name: 'Greg Au-Yeung',
      speaker_position: 'Senior Advisor at Shanghai Fudan University',
      video_url: 'https://www.youtube.com/watch?v=Fa4LP93tKx4&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-going-api-first-in-financial-services-within-greater-china-by-greg-auyeung-239047128'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Embark on a journey of API Management Maturity',
      speaker: 'helalnouri',
      speaker_img: 'speakers/helal-pro.jpg',
      speaker_name: 'Helal Nouri',
      speaker_position: 'Senior API Consultant at Rabobank',
      video_url: 'https://www.youtube.com/watch?v=v2wYkI56O_o&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-embark-on-a-journey-of-api-management-maturity-by-helal-nouri'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Orchestrating APIs at Scale',
      speaker: 'hieunguyennhu',
      speaker_img: 'speakers/hieu-pro.jpg',
      speaker_name: 'Hieu Nguyen Nhu',
      speaker_position: 'Microsoft Asia Pacific Cloud Native Technical Lead at Microsoft',
      video_url: 'https://www.youtube.com/watch?v=zNRlR0Lq5T4&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Data Gateways: Building “Data-as-a-Service” for the Hybrid Cloud',
      speaker: 'hugoguerrero',
      speaker_img: 'speakers/hugo-pro.jpg',
      speaker_name: 'Hugo Guerrero',
      speaker_position: 'APIs & Messaging Developer Advocate at Red Hat',
      video_url: 'https://www.youtube.com/watch?v=ajRultOEPs0&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-data-gateways-building-dataasaservice-for-the-hybrid-cloud-by-hugo-guerrero'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'The Three Things Your Developer Portal Can\'t Live Without',
      speaker: 'ilonakoren-deutsch',
      speaker_img: 'speakers/ilona-pro.jpg',
      speaker_name: 'Ilona Koren-Deutsch',
      speaker_position: 'Technical Documentation Manager at Twitch',
      video_url: 'https://www.youtube.com/watch?v=6VzfjcoUNHw&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-the-three-things-your-developer-portal-cant-live-without-by-ilona-korendeutsch'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'The Business of APIs',
      speaker: 'jedng',
      speaker_img: 'speakers/jed-pro.jpg',
      speaker_name: 'Jed Ng',
      speaker_position: 'Tech & API Investor',
      video_url: 'https://www.youtube.com/watch?v=JLBUjfrgoz8&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-the-business-of-apis-by-jed-ng'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Building an API Client SDK',
      speaker: 'jenksguo',
      speaker_img: 'speakers/jenks-pro.jpg',
      speaker_name: 'Jenks Guo',
      speaker_position: 'Developer Evangelist at Xero',
      video_url: 'https://www.youtube.com/watch?v=LJjxNEMURmI&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-building-an-api-client-sdk-by-jenks-guo'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Digital Native\'s Needs & Wants from Financial Services Providers',
      speaker: 'johanfantenberg',
      speaker_img: 'speakers/johan-pro.jpg',
      speaker_name: 'Johan Fantenberg',
      speaker_position: 'Principal Solution Architect at ForgeRock',
      video_url: 'https://www.youtube.com/watch?v=hWrSwnkmpNU&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-digital-natives-needs-wants-from-financial-services-providers-by-johan-fantenberg'
    },
    {
      category_title: 'Workshop Track',
      category: 'WorkshopTrack',
      badge_css: 'badge-soft-warning',
      title: 'Access Control for your API Endpoints with Auth0',
      speaker: 'johnlim',
      speaker_img: 'speakers/john-pro.jpg',
      speaker_name: 'John Lim',
      speaker_position: 'Solutions Engineer at Auth0',
      video_url: 'https://www.youtube.com/watch?v=GRON43D5HYU&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'The Future of Financial Institutions: Disintegration of the Value Chain Using API-driven Platforms',
      speaker: 'julianschillinger',
      speaker_img: 'speakers/julian-pro.jpg',
      speaker_name: 'Julian Schillinger',
      speaker_position: 'Co-Founder and Co-CEO of Privé Technologies',
      video_url: 'https://www.youtube.com/watch?v=QT05lF93Drg&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-the-future-of-financial-institutions-by-julian-schillinger'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'WT* is JWT',
      speaker: 'maciejtreder',
      speaker_img: 'speakers/maciej-pro.jpg',
      speaker_name: 'Maciej Treder',
      speaker_position: 'Senior Software Development Engineer at Akamai Technologies',
      video_url: 'https://www.youtube.com/watch?v=n70GSlgeglE&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-wt-is-jwt-by-maciej-treder'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Connectivity Rules Everything Around Us',
      speaker: 'marcopalladino',
      speaker_img: 'speakers/marco-pro.jpg',
      speaker_name: 'Marco Palladino',
      speaker_position: 'CTO and Co-Founder of Kong Inc.',
      video_url: 'https://www.youtube.com/watch?v=feCFfmDaHxM&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-connectivity-rules-everything-around-us-by-marco-palladino'
    },
    {
      category_title: 'Workshop Track',
      category: 'WorkshopTrack',
      badge_css: 'badge-soft-warning',
      title: 'The Evolution of API Drive Integration',
      speaker: 'nickychoo',
      speaker_img: 'speakers/nicky-pro.jpg',
      speaker_name: 'Nicky Choo',
      speaker_position: 'Head - Application, Integration and Management Platform Business of IBM APAC',
      video_url: 'https://www.youtube.com/watch?v=5cAYg-Wv37o&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'Getting Started with AsyncAPI',
      speaker: 'paultaylor',
      speaker_img: 'speakers/paul-pro.jpg',
      speaker_name: 'Paul Taylor',
      speaker_position: 'Strategic Advisor Director at MuleSoft',
      video_url: 'https://www.youtube.com/watch?v=G-MvHqmKs1A&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-getting-started-with-asyncapi-by-paul-taylor'
    },
    {
      category_title: 'Developers Track',
      category: 'DevelopersTrack',
      badge_css: 'badge-soft-info',
      title: 'The Trouble with Webhooks',
      speaker: 'philnash',
      speaker_img: 'speakers/phil-pro.jpg',
      speaker_name: 'Phil Nash',
      speaker_position: 'Developer Evangelist at Twilio',
      video_url: 'https://www.youtube.com/watch?v=BzuwvN1D3-w&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-the-trouble-with-webhooks-by-phil-nash'
    },
    {
      category_title: 'Workshop Track',
      category: 'WorkshopTrack',
      badge_css: 'badge-soft-warning',
      title: 'Protecting API Workloads Against OWASP Top 10 and Programming Language CVE\'s',
      speaker: 'rajeshbavanantham',
      speaker_img: 'speakers/rajesh-pro.jpg',
      speaker_name: 'Rajesh Bavanantham',
      speaker_position: 'Domain Architect at NGINX (Part of F5)',
      video_url: 'https://www.youtube.com/watch?v=7XpP5YoPH1M&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Connected Future of Business',
      speaker: 'richardlord',
      speaker_img: 'speakers/richard-pro.jpg',
      speaker_name: 'Richard Lord',
      speaker_position: 'Chief Information Officer, Commercial Banking – Asia Pacific at HSBC',
      video_url: 'https://www.youtube.com/watch?v=b3EpufeeiDw&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'How API-as-a-Service can Improve CX, Scalability, and Security with an Internal API Cloud',
      speaker: 'robwhiteley',
      speaker_img: 'speakers/rob-pro.jpg',
      speaker_name: 'Rob Whiteley',
      speaker_position: 'Vice President of NGINX (Part of F5)',
      video_url: 'https://www.youtube.com/watch?v=KbaebacWBis&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-how-apiasaservice-can-improve-cx-scalability-and-security-with-an-internal-api-cloud-by-rob-whiteley'
    },
    {
      category_title: 'Workshop Track',
      category: 'WorkshopTrack',
      badge_css: 'badge-soft-warning',
      title: 'Protecting API Workloads Against OWASP Top 10 and Programming Language CVE\'s',
      speaker: 'samuelleung',
      speaker_img: 'speakers/samuel-pro.jpg',
      speaker_name: 'Samuel Leung',
      speaker_position: 'Reginall Solutions Architect at NGINX (Part of F5)',
      video_url: 'https://www.youtube.com/watch?v=7XpP5YoPH1M&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: ''
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Art and Science of Rate Limits',
      speaker: 'shahnawazbacker',
      speaker_img: 'speakers/shahnawaz-pro.jpg',
      speaker_name: 'Shahnawaz Backer',
      speaker_position: 'Principal Security Advisor at F5 Networks',
      video_url: 'https://www.youtube.com/watch?v=NYXem7yRyDg&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-art-and-science-of-rate-limits-for-apis-by-shahnawaz-backer'
    },
    {
      category_title: 'Main Track',
      category: 'MainTrack',
      badge_css: 'badge-soft-success',
      title: 'Open Banking, Yin and Yang in Hong Kong',
      speaker: 'simonredfern',
      speaker_img: 'speakers/simon-pro.jpg',
      speaker_name: 'Simon Redfern',
      speaker_position: 'Founder and CEO of Open Bank Project',
      video_url: 'https://www.youtube.com/watch?v=Pun_45gZD7I&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-open-banking-yin-and-yang-in-hong-kong-by-simon-redfern'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'Fuzzing API to Identify API1: Broken Object Level Authorization Control and API5: Broken Function Level Authorization Control',
      speaker: 'stanleychou',
      speaker_img: 'speakers/stanley-pro.jpg',
      speaker_name: 'Stanley Chou',
      speaker_position: 'Head of Cybersecurity at OneDegree',
      video_url: 'https://www.youtube.com/watch?v=sQFpHOiq6ck&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-fuzzing-api-to-identify-api1-by-stanley-chou'
    },
    {
      category_title: 'Architects Track',
      category: 'ArchitectsTrack',
      badge_css: 'badge-soft-primary',
      title: 'The Future of Legacy - How to Leverage Legacy and On-Prem Assets in Your Digital Transformation with Digital-Driven Integration',
      speaker: 'zeevavidan',
      speaker_img: 'speakers/zeev-pro.jpg',
      speaker_name: 'Zeev Avidan',
      speaker_position: 'Chief Product Officer at OpenLegacy',
      video_url: 'https://www.youtube.com/watch?v=P34szQqcNSY&list=PLmEaqnTJ40OopeQY8swTurSDCic0g6cOT',
      slide_url: 'https://www.slideshare.net/APIdays_official/apidays-live-hong-kong-the-future-of-legacy-how-to-leverage-legacy-and-onprem-assets-in-your-digital-transformation-by-zeev-avidan'
    }
  ]
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

