const state = {
  practice_list: [
    {
      image_url: 'icon-24.svg',
      title: 'Thought Leadership',
      content: 'Active contributor in Mobile Business Insights & IBM DeveloperWorks.'
    },
    {
      image_url: 'icon-47.svg',
      title: 'Security in Mind',
      content: 'Security should be day 1 consideration and we put security as number 1 priority.'
    },
    {
      image_url: 'icon-50.svg',
      title: 'Born in the Cloud',
      content: 'We build with modern cloud architecture which scales with your business.'
    },
    {
      image_url: 'icon-2.svg',
      title: 'Research Backed',
      content: 'Applied researches led by Ph.D & funded under HKUST TSSSU Scheme & HKSTP Incu-Tech Programme.'
    },
    {
      image_url: 'icon-35.svg',
      title: 'Agile & DevOps Driven',
      content: 'Change is the new normal. We manage with Agile Methodology and modern DevOps Toolchains.'
    },
    {
      image_url: 'icon-30.svg',
      title: 'Certified UX Experts',
      content: 'UI Design is not UX. We got Certified Usability Analyst(CUA) in residency to deliver user-first solution.'
    }
  ]
}

const mutations = {
  //   SET_PROFILE: (state, profile) => {
  //     state.userProfile = profile
  //   }
}

export default {
  namespaced: true,
  state,
  mutations
}

